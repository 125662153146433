export enum QuestionType {
	BinaryChoice = "BinaryChoice",
	EmailChoice = "EmailChoice",
	IntermediateScreenWaves = "IntermediateScreenWaves",
	MultipleChipSelection = "MultipleChipSelection",
	MultipleChoice = "MultipleChoice",
	MultiplePictureChoice = "MultiplePictureChoice",
	NumberInput = "NumberInput",
	ProfileChoice = "ProfileChoice",
	QuizRestart = "QuizRestart",
	ResultChoice = "ResultChoice",
	SingleChipSelection = "SingleChipSelection",
	SingleChoice = "SingleChoice",
	SinglePictureChoice = "SinglePictureChoice",
	StartChoice = "StartChoice",
	SteplessSliderChoice = "SteplessSliderChoice",
	SwipeChoice = "SwipeChoice",
	VersusChoice = "VersusChoice",
	FreeTextInput = "FreeTextInput",
}
