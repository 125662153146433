import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, vModelCheckbox as _vModelCheckbox, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-32e52f22"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "emailForm" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "dataInput" }
const _hoisted_4 = { class: "error" }
const _hoisted_5 = { class: "dataInput" }
const _hoisted_6 = ["placeholder"]
const _hoisted_7 = { class: "error" }
const _hoisted_8 = { class: "dataInput" }
const _hoisted_9 = {
  class: "dsgvoText",
  for: "dsgvo"
}
const _hoisted_10 = {
  key: 0,
  class: "error"
}
const _hoisted_11 = { class: "dialogActions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Customer = _resolveComponent("Customer")!
  const _component_Mail = _resolveComponent("Mail")!
  const _component_DataPrivacy = _resolveComponent("DataPrivacy")!
  const _component_DialogComponent = _resolveComponent("DialogComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "text",
      innerHTML: _ctx.infoText
    }, null, 8, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_Customer, { class: "icon" }),
      _withDirectives(_createElementVNode("input", {
        id: "name",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event)),
        autocomplete: "off",
        placeholder: "Name",
        type: "text",
        onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.validateName && _ctx.validateName(...args)))
      }, null, 544), [
        [_vModelText, _ctx.name]
      ])
    ]),
    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.errors.name), 1),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_Mail, { class: "icon" }),
      _withDirectives(_createElementVNode("input", {
        id: "email",
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.email) = $event)),
        autocomplete: "off",
        placeholder: _ctx.restaurant.DisplayedTexts['E-Mail-Adresse'],
        type: "text",
        onChange: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.validateEmail && _ctx.validateEmail(...args)))
      }, null, 40, _hoisted_6), [
        [_vModelText, _ctx.email]
      ])
    ]),
    _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.errors.email), 1),
    _createElementVNode("div", _hoisted_8, [
      _withDirectives(_createElementVNode("input", {
        id: "dsgvo",
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.dsgvo) = $event)),
        type: "checkbox",
        onChange: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.validateDsgvo && _ctx.validateDsgvo(...args)))
      }, null, 544), [
        [_vModelCheckbox, _ctx.dsgvo]
      ]),
      _createElementVNode("label", _hoisted_9, [
        _createTextVNode(_toDisplayString(_ctx.restaurant.DisplayedTexts["Beim Absenden akzeptierst du unsere"]) + " ", 1),
        _createElementVNode("a", {
          class: "dataPrivacyLink",
          onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.showDataPrivacy = true))
        }, _toDisplayString(_ctx.restaurant.DisplayedTexts["Datenschutzbestimmungen"]), 1),
        _createTextVNode(".")
      ])
    ]),
    (_ctx.errors.dsgvo)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.errors.dsgvo), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_11, [
      (_ctx.rejectText)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "secondary",
            onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('reject')))
          }, _toDisplayString(_ctx.rejectText), 1))
        : _createCommentVNode("", true),
      (_ctx.confirmText)
        ? (_openBlock(), _createElementBlock("button", {
            key: 1,
            style: {"color":"var(--background-color)"},
            onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.setValues(true)))
          }, _toDisplayString(_ctx.confirmText), 1))
        : _createCommentVNode("", true)
    ]),
    (_ctx.showDataPrivacy)
      ? (_openBlock(), _createBlock(_component_DialogComponent, {
          key: 1,
          onClose: _cache[9] || (_cache[9] = ($event: any) => (_ctx.showDataPrivacy = false))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_DataPrivacy)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}