import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "17",
  height: "15",
  viewBox: "0 0 17 15",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_2 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      d: "M9.56832 13.9363C8.96666 14.4825 8.04041 14.4825 7.43874 13.9284L7.35166 13.8492C3.19541 10.0888 0.479989 7.6267 0.582905 4.55503C0.630405 3.2092 1.31916 1.91878 2.43541 1.15878C4.52541 -0.26622 7.10624 0.39878 8.49957 2.02961C9.89291 0.39878 12.4737 -0.274137 14.5637 1.15878C15.68 1.91878 16.3687 3.2092 16.4162 4.55503C16.5271 7.6267 13.8037 10.0888 9.64749 13.865L9.56832 13.9363Z",
      fill: _ctx.color
    }, null, 8, _hoisted_2)
  ]))
}