
import { Answer } from "@/entities/domain/Quiz/Answer";
import Restaurant from "@/entities/domain/Restaurant/Restaurant";
import { defineComponent, PropType } from "vue";

export default defineComponent({
	name: "NumberInput",
	props: {
		restaurant: {
			type: Object as PropType<Restaurant>,
		},
	},
	emits: ["answerClicked"],
	data: () => ({
		numberInput: undefined as string | number | string[] | undefined,
	}),
	computed: {
		answers(): Answer[] {
			return this.restaurant?.Quiz.getCurrentQuestion().Answers ?? [];
		},
	},
	mounted() {
		(this.$refs.numberInput as any).focus();
	},
	methods: {
		setAnswer() {
			let answerToSet;

			this.answers.forEach((answer) => {
				if (this.numberInput && answer.Text && eval(this.numberInput + answer.Text)) {
					answerToSet = answer;
				}
			});

			this.$emit("answerClicked", {
				answerId: answerToSet.id,
				answerValue: this.numberInput,
			});
		},
	},
});
