
import { defineComponent, PropType } from "vue";
import Restaurant from "@/entities/domain/Restaurant/Restaurant";

export default defineComponent({
	name: "SingleChoice",
	props: {
		restaurant: {
			type: Object as PropType<Restaurant>,
		},
	},
	emits: ["setAnswer"],
	computed: {
		answers() {
			return this.restaurant?.Quiz.getCurrentQuestion().Answers;
		},
	},
});
