import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, Transition as _Transition, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6dd24666"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "tooltip-container"
}
const _hoisted_2 = { class: "tooltip" }
const _hoisted_3 = { class: "starContainer" }
const _hoisted_4 = ["onMouseover", "onClick"]
const _hoisted_5 = { class: "actionsContainer" }
const _hoisted_6 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FeedbackOverlay = _resolveComponent("FeedbackOverlay")!
  const _component_Star = _resolveComponent("Star")!
  const _component_Checkmark = _resolveComponent("Checkmark")!

  return (_openBlock(), _createBlock(_Transition, null, {
    default: _withCtx(() => [
      (_ctx.showQuestion && _ctx.visible)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              (_ctx.isOverlayVisble)
                ? (_openBlock(), _createBlock(_component_FeedbackOverlay, {
                    key: 0,
                    onCloseClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isOverlayVisble = false)),
                    onFeedbackReasonSelected: _ctx.onMinorRatingCauseSelected,
                    reasonArray: _ctx.possibleFeedbackReasons,
                    restaurant: _ctx.restaurant
                  }, null, 8, ["onFeedbackReasonSelected", "reasonArray", "restaurant"]))
                : _createCommentVNode("", true),
              _createElementVNode("div", null, [
                _createTextVNode(_toDisplayString(_ctx.restaurant.DisplayedTexts["Wie hat dir das Quiz gefallen?"]) + " ", 1),
                _createElementVNode("div", _hoisted_3, [
                  (_openBlock(), _createElementBlock(_Fragment, null, _renderList([1, 2, 3, 4, 5], (i) => {
                    return _createElementVNode("div", {
                      key: i,
                      onMouseover: ($event: any) => (!_ctx.review ? (_ctx.preview = i) : null),
                      onClick: ($event: any) => (_ctx.review = i)
                    }, [
                      (_ctx.preview && i <= (_ctx.review ?? _ctx.preview))
                        ? (_openBlock(), _createBlock(_component_Star, {
                            key: 0,
                            class: "star",
                            fill: "#FFCC00",
                            style: _normalizeStyle({ opacity: _ctx.review ? 1 : 0.5 })
                          }, null, 8, ["style"]))
                        : (_openBlock(), _createBlock(_component_Star, {
                            key: 1,
                            class: "star",
                            fill: "#DEDEDE"
                          }))
                    ], 40, _hoisted_4)
                  }), 64))
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("button", {
                    class: _normalizeClass({ sended: _ctx.sended, disabled: _ctx.review === null }),
                    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.sendReview && _ctx.sendReview(...args))),
                    disabled: _ctx.review === null
                  }, [
                    _createTextVNode(_toDisplayString(_ctx.sended
								? _ctx.restaurant.DisplayedTexts["Gespeichert"]
								: _ctx.restaurant.DisplayedTexts["Speichern"]) + " ", 1),
                    (_ctx.sended)
                      ? (_openBlock(), _createBlock(_component_Checkmark, {
                          key: 0,
                          fill: "white",
                          class: "checkmark"
                        }))
                      : _createCommentVNode("", true)
                  ], 10, _hoisted_6)
                ])
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}