export default {
	offsetParams: {
		1: { xOffset: -16, yOffset: +18, fontSize: 50 },
		3: { xOffset: -48, yOffset: +18, fontSize: 50 },
		5: { xOffset: -45, yOffset: +11, fontSize: 30 },
	},

	addText(b, p, text): void {
		const params = Object.assign({}, this.offsetParams[text.length]);
		let rotation = "";
		if (b.width < b.height) {
			params.yOffset = this.offsetParams[text.length].xOffset;
			params.xOffset = -this.offsetParams[text.length].yOffset;
			rotation = " rotate(90)";
		}
		const t = document.createElementNS("http://www.w3.org/2000/svg", "text");
		t.setAttribute(
			"transform",
			"translate(" +
				(b.x + b.width / 2 + params.xOffset) +
				" " +
				(b.y + b.height / 2 + params.yOffset) +
				")" +
				rotation
		);
		t.textContent = text;
		t.setAttribute("fill", "white");
		t.setAttribute("font-size", params.fontSize);
		t.setAttribute("font-weight", "bold");
		t.setAttribute("font-family", '"Open Sans", Roboto, Helvetica, Arial, sans-serif');
		p.parentNode.insertBefore(t, p.nextSibling);
	},

	copyStylesInline(destinationNode, sourceNode): void {
		const containerElements = ["svg", "g"];
		for (let cd = 0; cd < destinationNode.childNodes.length; cd++) {
			const child = destinationNode.childNodes[cd];
			if (containerElements.indexOf(child.tagName) !== -1) {
				this.copyStylesInline(child, sourceNode.childNodes[cd]);
				continue;
			}
			if (typeof sourceNode.childNodes[cd] === "object") continue;
			const style =
				sourceNode.childNodes[cd].currentStyle ||
				window.getComputedStyle(sourceNode.childNodes[cd]) ||
				null;
			if (style === "undefined" || style == null) continue;
			for (let st = 0; st < style.length; st++) {
				child.style.setProperty(style[st], style.getPropertyValue(style[st]));
			}
		}
	},

	addLocationLabels(locations, svg, copy): void {
		const shelfs = {};
		for (const [idx, location] of locations.entries()) {
			if (location in shelfs) {
				shelfs[location].push(idx + 1);
			} else {
				shelfs[location] = [idx + 1];
			}
		}
		for (const shelf in shelfs) {
			const textArray: string[] = [];
			for (const i of shelfs[shelf]) {
				textArray.push(i);
			}
			const text = textArray.join("+");
			this.addText(svg.getElementById(shelf).getBBox(), copy.getElementById(shelf), text);
		}
	},

	toGrayscale(svg, locations): void {
		svg.querySelectorAll("[fill]").forEach((ele) => {
			if (ele.getAttribute("fill") === "#BDA394") {
				ele.setAttribute("fill", locations.includes(ele.id) ? "#000" : "#aaa");
			}
		});
	},

	svgToURI(svg, locations) {
		const copy = svg.cloneNode(true);
		this.copyStylesInline(copy, svg); // currently not needed but handy
		this.addLocationLabels(locations, svg, copy);
		this.toGrayscale(copy, locations);
		const canvas = document.createElement("canvas");
		const bbox = svg.getBBox();

		const MAX_WIDTH = 584;
		let width = bbox.width;
		let height = bbox.height;
		height = height * (MAX_WIDTH / width);
		width = MAX_WIDTH;

		canvas.width = width;
		canvas.height = height;
		const ctx = canvas.getContext("2d");
		ctx?.clearRect(0, 0, bbox.width, bbox.height);
		const data = new XMLSerializer().serializeToString(copy);
		const DOMURL = window.URL || window.webkitURL || window;
		const svgBlob = new Blob([data], { type: "image/svg+xml;charset=utf-8" });
		const url = DOMURL.createObjectURL(svgBlob);

		return new Promise((resolve, reject) => {
			const img = new Image();
			img.onload = function () {
				ctx?.drawImage(img, 0, 0, width, height);
				DOMURL.revokeObjectURL(url);
				const imgURI = canvas.toDataURL("image/png").replace("data:image/png;base64,", "");
				resolve(imgURI);
			};
			img.onerror = reject;
			img.src = url;
		});
	},

	// (async () => {
	//   let wines = [
	//     {
	//       name: 'Wein1',
	//       shelf: 'E-SPEYER_x5F_1300',
	//       rebsorte: 'Rebsorte1'
	//     },
	//     {
	//       name: 'Wein2',
	//       shelf: 'E-SPEYER_x5F_1370',
	//       rebsorte: 'Rebsorte2'
	//     },
	//     {
	//       name: 'Wein3',
	//       shelf: 'E-SPEYER_x5F_1418',
	//       rebsorte: 'Rebsorte3'
	//     }
	//   ];

	//   if(wines.length){
	//     let svg = document.getElementById(wines[0].shelf).closest('svg')
	//     const url = await svgToURI(svg, wines.map(w => w.shelf));
	//     const requestOptions = {
	//       method: "POST",
	//       headers: {
	//           Accept: 'application/json',
	//           "Content-Type": "application/json"
	//       },
	//       body: JSON.stringify({
	//         'image': url,
	//         'wines': wines
	//       })
	//     };
	//     await fetch('http://localhost:8080/print', requestOptions)
	//   }
	// })();
};
