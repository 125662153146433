
import { defineComponent, PropType } from "vue";
import MultiplePictureChoiceButton from "@/components/Elements/MultiplePictureChoiceButton.vue";
import Restaurant from "@/entities/domain/Restaurant/Restaurant";
import { Answer } from "@/entities/domain/Quiz/Answer";

export default defineComponent({
	name: "VersusChoice",
	components: { MultiplePictureChoiceButton },
	props: {
		restaurant: {
			type: Object as PropType<Restaurant>,
		},
	},
	emits: ["answerClicked", "setAnswer"],
	data() {
		return { selectedAnswerId: -1 };
	},
	computed: {
		answers(): Answer[] {
			return this.restaurant?.Quiz.getCurrentQuestion().Answers ?? [];
		},
	},
	methods: {
		answerClicked(answer: Answer) {
			this.selectedAnswerId = answer.Id;
			this.$emit("setAnswer", { answerId: answer.Id, answerValue: answer.Text }); // TODO: Check if this works vs text_de
		},
		getThumbImage() {
			if (this.restaurant?.FrontendDesign?.VsThumbImage) {
				return this.restaurant.FrontendDesign.VsThumbImage;
			}

			return require("@/assets/img/tailorwine_vs.svg");
		},
	},
});
