import { IMatchingResponse } from "@/interfaces/api/responses/Matching/IMatchingResponse";
import { RestaurantQuizConfig } from "../Restaurant/RestaurantQuizConfig";
import { AromaType } from "./AromaType";
import { GrapeType } from "./GrapeType";
import { TasteType } from "./TasteType";
import { Wine } from "./Wine";

export class Match {
	TasteType: TasteType;
	Grapes: GrapeType;
	Aromas: AromaType[];
	Wines: Wine[];
	QrUrl: string;
	SessionId: string;
	SessionHash: string;
	ProvidedContact: boolean;
	ClaimedWine: boolean;
	QrCodeMode: boolean;
	RestaurantConfig?: RestaurantQuizConfig;

	constructor(match: Match) {
		this.TasteType = match.TasteType;
		this.Grapes = match.Grapes;
		this.Aromas = match.Aromas;
		this.Wines = match.Wines;
		this.QrUrl = match.QrUrl;
		this.SessionId = match.SessionId;
		this.SessionHash = match.SessionHash;
		this.ProvidedContact = match.ProvidedContact;
		this.ClaimedWine = match.ClaimedWine;
		this.QrCodeMode = match.QrCodeMode;
		this.RestaurantConfig = match.RestaurantConfig;
	}

	static createFromApiResponse(response: IMatchingResponse): Match {
		const newMatch = {
			TasteType: TasteType.createFromApiResponse(response.type),
			Grapes: GrapeType.createFromApiResponse(response.grapes),
			Aromas: response.aromas.map((aroma) => AromaType.createFromApiResponse(aroma)),
			Wines: response.wines.map((wine) => Wine.createFromApiResponse(wine)),
			QrUrl: response.qrUrl,
			SessionId: response.session_id,
			SessionHash: response.hash,
			ProvidedContact: response.providedContact,
			ClaimedWine: response.claimedWine,
			QrCodeMode: response.qrCodeMode === "true",
			RestaurantConfig: response.config ? RestaurantQuizConfig.createFromApiResponse(response.config) : null,
		} as Match;

		return newMatch;
	}
}
