
import { defineComponent } from "vue";

export default defineComponent({
	name: "Arrow",
	props: {
		color: {
			type: String,
			default: "black",
		},
	},
});
