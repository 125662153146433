

import { defineComponent, nextTick, PropType } from "vue";
import SingleMessage from "./SingleMessage.vue";
import Typing from "../../BaseComponents/Typing.vue";
import GetDocumentElementById from "@/utils/GetDocumentElementById";
import Restaurant from "@/entities/domain/Restaurant/Restaurant";
import PaperPlane from "@/components/svg/PaperPlane.vue";
import QuizService from "@/services/QuizService";
import Arrow from "@/components/svg/Arrow.vue";
import Quiz from "@/entities/domain/Quiz/Quiz";
import getImageFromSource from "@/utils/getImageFromSouce";
import playNotification from "@/utils/playNotification";
import { FrontendDesign } from "@/entities/domain/Restaurant/FrontendDesign";
import AddToStyling from "@/utils/AddToStyling";
import { Chatbot } from "@/entities/domain/Chatbot/Chatbot";
import { Message } from "@/entities/domain/Chatbot/Message";
import { MessageTypes, MessageSender } from "@/entities/enums/ChatbotEnums";

export default defineComponent({
	name: "ChatWindow",
	components: { SingleMessage, Typing, PaperPlane, Arrow },
	props: {
		restaurant: {
			type: Object as PropType<Restaurant>,
			required: true,
		},
	},
	data() {
		return {
			open: this.restaurant.RestaurantQuizConfig.FullscreenMode ?? false,
			chatWindowMode: false,
			showInfoMessage: false,
			question: "" as string,

			fullScreenMode: this.restaurant.RestaurantQuizConfig.FullscreenMode ?? false,
			windowHeight: 0,

			inputActive: true,

			chatbot: new Chatbot({
				MessageHistory: [] as Message[],
				Token: this.restaurant.Token,
			} as Chatbot),
		};
	},
	created() {
		this.windowHeight = window.visualViewport?.height ?? window.innerHeight;
		window.addEventListener('resize', this.updateWindowHeight);
	},
	beforeUnmount() {
		window.removeEventListener('resize', this.updateWindowHeight);
	},

	async mounted() {
		if (
			this.restaurant.RestaurantQuizConfig.ExtraChatBotQuiz &&
			this.restaurant.RestaurantCode
		) {
			const extraQuizRestaurant = await QuizService.getQuiz(
				this.restaurant.RestaurantCode,
				this.restaurant.RestaurantQuizConfig.ExtraChatBotQuiz
			);
			
			QuizService.getQuiz(this.restaurant.RestaurantCode);

			if (extraQuizRestaurant?.Data?.quiz)
				this.chatbot.ExtraQuiz = Quiz.createFromApiResponse(extraQuizRestaurant?.Data.quiz);
		}

		setTimeout(() => {
			if (
				!this.open &&
				this.restaurant.isQuizClosed &&
				localStorage.getItem("chatWindowOpened") !== "true" &&
				localStorage.getItem("seenInfoMessage") !== "true"
			) {
				playNotification();
				this.showInfoMessage = true;
			}
		}, 5000);

		AddToStyling(`
			#chatbot{background: linear-gradient(180deg, ${
				this.restaurant.FrontendDesign?.WineWizard?.FirstColorWave ?? "#1C1027"
			} 0%, ${
			this.restaurant.FrontendDesign?.WineWizard?.SecondColorWave ?? "#90173E"
		} 100%);}`);

		this.updateWindowHeight()
		// When mounted or returnend to main page, message history should be "cleared"
		this.chatbot.clearHistory();
		
	},
	watch: {
		open: {
			handler: async function (newVal, oldVal) {
				if (newVal) {
					this.showInfoMessage = false;
					QuizService.sendEvent("Chatbot Opened", "ChatbotOpened")
				}
				await this.updateScroll();

				localStorage.setItem("chatWindowOpened", "true");
			},
		},
		showInfoMessage: {
			handler: function (newVal) {
				if (newVal) localStorage.setItem("seenInfoMessage", "true");
			},
		},
	},
	methods: {
		startQuiz(hardStart?: boolean) {
			if (hardStart) this.$emit("hardStartQuiz");
			else this.$emit("startQuiz");
		},
		focusInput() {
			setTimeout(()=>{
				(this.$refs.input as HTMLInputElement).focus();
			},200)
		},
		openChat(){
			this.chatbot.MessageHistory.push({
				Id: this.chatbot.MessageHistory.length + 1,
				Text: this.chatbotTexts?.ai_question ?? this.restaurant.DisplayedTexts["Zu welcher Speise suchst du den passenden Wein?"],
				Type: MessageTypes.NORMAL,
				Sender: MessageSender.WILLI,
			} as Message);

			this.chatWindowMode = true;
			
			this.updateScroll();

			this.focusInput();
		},
		async sendMessage() {
			if (this.question === "" || !this.restaurant.Token) return;

			// TODO: activate Event when available
			QuizService.sendEvent(JSON.stringify({topicLabel: "Speisen", questionTextVal: "Zu welchem Gericht soll der Wein passen", answerTextVal: this.question}), "freeTextInput")

			// this.chatbot.MessageHistory = this.chatbot.MessageHistory.map((message) => {
			// 	// make each message as Ignore type
			// 	return {
			// 		...message,
			// 		Type: MessageTypes.IGNOREINHISTORY,
			// 	} as Message;
			// });

			// this.chatbot.MessageHistory.push({
			// 	Id: this.chatbot.MessageHistory.length + 1,
			// 	Text: "Zu welcher Speise suchst du den passenden Wein?",
			// 	Type: MessageTypes.NORMAL,
			// 	Sender: MessageSender.WILLI,
			// } as Message);

			this.chatWindowMode = true;

			this.updateScroll();

			this.chatbot.CurrentQuestion = this.question;
			this.question = "";
			// await this.chatbot.sendMessageGpt3();

			// Send foodMessage as event
			// QuizService.sendEvent(
			// 	JSON.stringify({
			// 		topicLabel: "Speisen",
			// 		questionTextVal: "Zu welchem Gericht soll der Wein passen",
			// 		answerTextVal: this.chatbot.CurrentQuestion
			// 	}),
			// 	"freeTextInput")
			const result = await this.chatbot.SendMessage(this.restaurant, true, undefined, false);

			setTimeout(() => {
				this.updateScroll(
					this.chatbot.MessageHistory[this.chatbot.MessageHistory.length - 2].Id
					);
			}, 1)
			if(!result?.Success) return;

			setTimeout(() => {
				this.chatbot.Loading = true;
			}, 3000);

			setTimeout(async () => {
				this.chatbot.MessageHistory.push({
					Id: this.chatbot.MessageHistory.length + 1,
					Text: this.restaurant.DisplayedTexts["War diese Antwort hilfreich?"],
					Type: MessageTypes.IGNOREINHISTORY,
					Sender: MessageSender.WILLI,
				} as Message);
				this.chatbot.MessageHistory.push({
					Id: this.chatbot.MessageHistory.length + 1,
					Text: "👍   " + this.restaurant.DisplayedTexts["Ja"] + "!",
					TextSecondary: "👎  " + this.restaurant.DisplayedTexts["Nein"],
					Type: MessageTypes.TWOBUTTONS,
					Sender: MessageSender.WILLI,
					PrimaryFunction: () => this.sendFeedback(1),
					SecondaryFunction: () => this.sendFeedback(-1),
				} as Message);
				this.chatbot.Loading = false;
			}, 4000);
		},
		async sendFeedback(feedback: 1 | -1) {
			QuizService.sendChatbotQuestionFeedback(this.chatbot.CurrentQuestion, feedback);

			this.chatbot.MessageHistory.pop();

			this.chatbot.MessageHistory.push({
				Id: this.chatbot.MessageHistory.length + 1,
				Text: feedback === 1 ? "👍   " + this.restaurant.DisplayedTexts["Ja"] + "!" : "👎  " + this.restaurant.DisplayedTexts["Nein"],
				Type: MessageTypes.IGNOREINHISTORY,
				Sender: MessageSender.USER,
			} as Message);

			this.chatbot.Loading = true;

			setTimeout(async () => {
				this.chatbot.MessageHistory.push({
					Id: this.chatbot.MessageHistory.length + 1,
					Text: this.restaurant.DisplayedTexts["Vielen Dank für dein Feedback! Möchtest du zu einer weiteren Speise einen Wein finden?"],
					Type: MessageTypes.IGNOREINHISTORY,
					Sender: MessageSender.WILLI,
				} as Message);

				this.chatbot.MessageHistory.push({
					Id: this.chatbot.MessageHistory.length + 1,
					Text: "❔   " + this.restaurant.DisplayedTexts["Weiteren Wein finden"] + " →",
					TextSecondary: "🍷  " + this.restaurant.DisplayedTexts["Weinquiz starten"] + " →",
					Type: MessageTypes.TWOBUTTONS,
					Sender: MessageSender.WILLI,
					PrimaryFunction: () => {
						this.chatbot.MessageHistory.pop();

						this.chatbot.MessageHistory.push({
							Id: this.chatbot.MessageHistory.length + 1,
							Text: "❔   " + this.restaurant.DisplayedTexts["Weiteren Wein finden"] + " →",
							Type: MessageTypes.IGNOREINHISTORY,
							Sender: MessageSender.USER,
						} as Message);

						this.chatbot.Loading = true;

						setTimeout(async () => {
							this.chatbot.MessageHistory.push({
								Id: this.chatbot.MessageHistory.length + 1,
								Text: this.restaurant.DisplayedTexts["Zu welchem Essen möchtest du einen weiteren Wein finden?"],
								Type: MessageTypes.NORMAL,
								Sender: MessageSender.WILLI,
							} as Message);

							this.chatbot.Loading = false;
						}, 1000);
					},
					SecondaryFunction: () => this.$emit("startQuiz"),
				} as Message);

				this.chatbot.Loading = false;
			}, 1000);
		},
		async updateScroll(messageIdToScrollTo?: number) {
			await nextTick();
			await nextTick();
			const messageWindow = GetDocumentElementById("messages");
			if (messageWindow && !messageIdToScrollTo) {
				messageWindow.scrollTop = messageWindow.scrollHeight;
				return;
			}

			if (messageWindow && messageIdToScrollTo) {
				const selectedMessage = document.getElementById("message-" + messageIdToScrollTo);

				if (!selectedMessage) return;

				messageWindow.scrollTop = selectedMessage.offsetTop;
				return;
			}
		},
		async startChatQuiz() {
			this.inputActive = false;
			this.chatbot.StartConversationWithQuiz();
			this.chatWindowMode = true;
			await this.updateScroll();
		},
		goBack(e) {
			e.preventDefault();
			this.chatbot.clearHistory();
			this.inputActive = true;
			this.chatWindowMode || this.restaurant.RestaurantQuizConfig.FullscreenMode ? (this.chatWindowMode = false) : (this.open = false);
		},
		updateWindowHeight() {
			setTimeout(()=> this.windowHeight = window.visualViewport?.height ?? window.innerHeight, 500)
		}
	},
	computed: {
		activInputForUser() {
			return (
				!this.chatbot.Loading &&
				this.chatbot.MessageHistory.at(-1)?.Type !== MessageTypes.ONEBUTTON &&
				this.chatbot.MessageHistory.at(-1)?.Type !== MessageTypes.TWOBUTTONS
			);
		},
		avatar() {
			return getImageFromSource(
				this.restaurant.FrontendDesign?.WineWizard?.AvatarImageUrl ??
					"@/assets/img/wine_wizard_avatar.svg"
			);
		},
		chatbotTexts() {
			return this.restaurant.FrontendDesign?.WineWizard?.TextConventions ?? undefined;
		},
		// windowHeight() {
		// 	return window.visualViewport?.height ?? window.innerHeight;
		// }
	},
});
