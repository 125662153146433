
import { defineComponent, ref } from 'vue';

export default defineComponent({
    name: 'Multiselect',
    setup(props, { emit }) { // Zugriff auf die emit Methode über das Context-Objekt
        const search = ref('');
        const showOptions = ref(false);
        const options = ref(['Option 1', 'Option 2', 'Option 3']);
        const filteredOptions = ref([...options.value]);

        function filterOptions() {
            if (search.value === '') {
                filteredOptions.value = options.value;
            } else {
                filteredOptions.value = options.value.filter(option =>
                    option.toLowerCase().includes(search.value.toLowerCase())
                );
            }
        }

        function selectOption(option: string) {
            search.value = option;
            showOptions.value = false;
            emit('change', option); // Emitting the change event
        }

        function hideOptions() {
            setTimeout(() => {
                showOptions.value = false;
            }, 200);
        }

        return { search, showOptions, filteredOptions, selectOption, filterOptions, hideOptions };
    },
    emits: ['change'] // Deklarieren des Events, das diese Komponente emittieren kann
});
