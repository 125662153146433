
import { defineComponent, PropType } from "vue";

import Star from "@/components/svg/Star.vue";
import Checkmark from "@/components/svg/Checkmark.vue";
import { Translations } from "@/entities/domain/Translations";
import QuizService from "@/services/QuizService";
import FeedbackOverlay from "../BaseComponents/FeedbackOverlay.vue";
import Restaurant from "@/entities/domain/Restaurant/Restaurant";

export default defineComponent({
	name: "FeedbackStars",
	components: { Star, Checkmark, FeedbackOverlay },
	props: {
		restaurant:{
			type: Object as PropType<Restaurant>,
			required: true
		},
		baseComponentTranslations: {
			type: Object as PropType<Translations>,
			required: true,
		},
		visible: Boolean,
	},
	data() {
		return {
			review: null as number | null,
			preview: 3 as number | null,
			showQuestion: true,
			sended: false,
			isOverlayVisble: false,
		};
	},
	mounted() {
		setTimeout(() => {
			this.showQuestion = true;
		}, 5000);
	},
	methods: {
		onMinorRatingCauseSelected(minorRatingCause: string) {
			this.sendReview(minorRatingCause);
		},
		async sendReview(cause) {
			if (this.review === null) return;


			if (this.review && this.review <= 3 && !this.isOverlayVisble) {
				this.isOverlayVisble = true;
			} else {
				this.isOverlayVisble = false;
				this.sended = true;
				await QuizService.sendEvent(JSON.stringify({ rating: this.review, cause: cause }), "feedback");
				// if (cause)
				//   await QuizService.sendEvent(cause, "feedbackCause");
				setTimeout(() => {
					this.showQuestion = false;
				}, 3000);
			}
		},
	},
	computed: {
		possibleFeedbackReasons() {
			return [
				{
					Title: this.restaurant.DisplayedTexts['Weinauswahl'],
					Value: 'wineSelection',
					SubSelection: [
						{
							Title: this.restaurant.DisplayedTexts['Farbe'],
							Value: 'wineColor',
						},
						{
							Title: this.restaurant.DisplayedTexts['Preis'],
							Value: 'winePrice',
						},
						{
							Title: this.restaurant.DisplayedTexts['Geschmack'],
							Value: 'wineTaste',
						},
					]
				},
				{
					Title: this.restaurant.DisplayedTexts['Dauer'],
					Value: 'duration'
				},
				{
					Title: this.restaurant.DisplayedTexts['Fragen'],
					Value: 'questions'
				}
			];
		}
	}
});
