
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'Close',
    props: {
        fill: {
            type: String,
            default: null as string | null,
        },
    },
})
