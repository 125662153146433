
import { defineComponent } from "vue";
import Quiz from "./components/Quiz.vue";

export default defineComponent({
	name: "App",
	components: {
		Quiz,
	},
	data() {
		return { restaurantCode: "" as string, autoRedirect: false, showQuiz: false, mode: "", transparentMode: false, matchInfo: "", language: "" };
	},
	mounted() {
		// disable Matomo Screenshots to later activate them, when screen is finished loading
		// window["_paq"].push(["HeatmapSessionRecording::disable"]);
		this.restaurantCode = this.$el.parentNode.getAttribute("restaurant-code");
		this.autoRedirect = !!this.$el.parentNode.getAttribute("auto-redirect");
		this.language = this.$el.parentNode.getAttribute("language");

		this.transparentMode = this.$el.parentNode.getAttribute("transparent-mode") !== null;
		this.mode = this.$el.parentNode.getAttribute("mode");
		
		this.matchInfo = this.$el.parentNode.getAttribute("match-info");

	},
});
