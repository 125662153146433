import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7460e66c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "numberInput" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      ref: "numberInput",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.numberInput) = $event)),
      autocomplete: "off",
      type: "number",
      onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.setAnswer && _ctx.setAnswer(...args)))
    }, null, 544), [
      [_vModelText, _ctx.numberInput]
    ])
  ]))
}