import GetDocumentElementById from "./GetDocumentElementById";

export default function AutoScrollToId(
	id: string,
	behavior: ScrollBehavior = "smooth",
	block: ScrollLogicalPosition = "start"
) {
	GetDocumentElementById(id)?.scrollIntoView({
		behavior: behavior,
		block: block,
		inline: "nearest",
	});
}
