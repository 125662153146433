
import { defineComponent, PropType } from "vue";
import Restaurant from "@/entities/domain/Restaurant/Restaurant";
import { Answer } from "@/entities/domain/Quiz/Answer";

export default defineComponent({
	name: "AnimatedWaves",
	props: {
		restaurant: {
			type: Object as PropType<Restaurant>,
		},
	},
	emits: ["answerClicked", "setAnswer"],
	data() {
		return {
			counterText: 3,
			interval: undefined as number | undefined,
		};
	},
	computed: {
		answers(): Answer[] {
			return this.restaurant?.Quiz.getCurrentQuestion().Answers ?? [];
		},
		frontendDesign() {
			return this.restaurant?.FrontendDesign;
		},
	},
	watch: {
		answers: {
			handler() {
				// window.scrollTo(0, 0);
			},
			deep: true,
		},
	},
	mounted() {
		// window.scrollTo(0, 0);
		this.$emit("answerClicked", { answerId: this.answers[0].Id, answerValue: 1 });

		const countdownData = this.restaurant?.Quiz.getCurrentQuestion().FrontendSettings.find(
			(el) => el.Key === "waveCountdown"
		)?.Value;

		if (countdownData) this.counterText = Number(countdownData);

		this.interval = setInterval(() => {
			if (this.counterText > 0) this.counterText -= 1;
			else {
				this.counterText = 3;
				this.toNextQuestion();
			}
		}, 1000);
	},
	methods: {
		toNextQuestion() {
			clearTimeout(this.interval);
			this.$emit("setAnswer", null);
		},
		getImg(src) {
			if (src[0] === "/") return require("@/assets" + src);

			return src;
		},
	},
});
