export enum WineButtonAction {
	SAVEMODE = "SAVEMODE",
	OPENNAVIGATIONMAP = "OPENNAVIGATIONMAP",
	REDIRECT = "REDIRECT",
	REDIRECTWITHWARNING = "REDIRECTWITHWARNING",
	STARTTASTING = "STARTTASTING",
	SHOWQRCODE = "SHOWQRCODE",
	EXPLOREMODE = "EXPLOREMODE",
}

export enum Languages {
	DE = "de",
	EN = "en",
	IT = 'it',
	FR = 'fr-FR',
}

export enum BottleClickAction {
	ZOOM = "ZOOM",
	NONE = "NONE",
}

export enum WineColor {
	RED = "RED",
	WHITE = "WHITE",
	ROSE = "ROSE",
}

export enum StylingVariant {
	NORMAL = "NORMAL",
	CHATBOT = "CHATBOT",
}

export enum NavigationMode {
	NORMAL = "NORMAL",
	SEARCH = "SEARCH"
}

export enum WineCardVersion {
	FIRST = "FIRST",
	SECOND = "SECOND",
}