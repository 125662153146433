
export enum WineFeedbackCause {
    COLOR = "Color",
    PRICE = "Price",
    TASTE_BRAND = "Producer",
    TASTE_GRAPE = "Grape",
    TASTE_SWEETNESS = "Sweetness",
    TASTE_ACIDITY = "Acidity",
    LABEL_TOO_MODERN = "LabelTooModern",
    LABEL_TOO_OLD_FASHIONED = "LabelTooOldFashioned",
    LABLE_TOO_CHEAP = "LabelTooCheap",
    LABLE_TOO_COMPLICATED = "LabelTooComplicated",
}