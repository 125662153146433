
import Restaurant from "@/entities/domain/Restaurant/Restaurant";
import { defineComponent } from "vue";

export default defineComponent({
	name: "Loading",
	props: {
		loading: {
			type: Boolean,
			default: true,
		},
		timeOutInMs: {
			type: Number,
			default: 10000,
		},
		restaurant: {
			type: Restaurant,
			required: true,
		},
	},
	data() {
		return {
			loadingTimeOut: false as boolean,
		};
	},
	watch: {
		loading(newValue) {
			if (!newValue) {
				this.loadingTimeOut = false;
			}
		},
	},
	mounted() {
		setTimeout(() => {
			this.loadingTimeOut = true;
		}, this.timeOutInMs);
	},
	methods: {
		reloadPage() {
			window.location.reload();
		},
	},
});
