import { App } from "vue";

// export default function clickOutsideHandler(app: App<Element>) {
// 	app.directive("click-outside", {
// 		mounted: (el, binding) => {
// 			el.clickOutsideEvent = function (event) {
// 				// Check that the click was outside the element and its children
// 				if (!(el == event.target || el.contains(event.target))) {
// 					// if it was, call method provided in attribute value
// 					binding.value();
// 				}
// 			};
// 			document.body.addEventListener("click", el.clickOutsideEvent);
// 		},
// 	});
// }

export default {
	install: (app: App<Element>) => {
        

		app.directive("click-outside", {
			mounted: (el, binding) => {
				el.clickOutsideEvent = function (event) {
					// Check that the click was outside the element and its children
					if (!(el == event.target || el.contains(event.target))) {
						// if it was, call method provided in attribute value
						binding.value();
					}
				};
				document.body.addEventListener("click", el.clickOutsideEvent);
			},
		});
	},
};
