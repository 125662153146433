import { toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5499c158"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "MultipleChipSelection" }
const _hoisted_2 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createTextVNode(_toDisplayString(_ctx.restaurant?.DisplayedTexts["Mehrfachauswahl möglich"]) + " ", 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Math.ceil(_ctx.answers.length / 5), (index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "flex auto"
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.answers?.slice((index - 1) * 5, index * 5), (answer) => {
          return (_openBlock(), _createElementBlock("div", {
            id: answer.Id.toString(),
            key: answer.Id,
            class: _normalizeClass(["chip", { selected: _ctx.selectedChipAnswerIds.includes(answer.Id) }]),
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onChipClicked && _ctx.onChipClicked(...args)))
          }, _toDisplayString(answer.Text), 11, _hoisted_2))
        }), 128))
      ]))
    }), 128))
  ]))
}