import { Wine } from "./Wine";

export class WineListFilter {
	private _WineFilterOptions: WineFilter[];

	constructor(WineListFilter?: WineListFilter) {
		this._WineFilterOptions = WineListFilter?._WineFilterOptions ?? [];
	}

	get WineFilterOptions(): WineFilter[] {
		return this._WineFilterOptions;
	}

	set WineFilterOptions(options: WineFilter[]) {
		let tempFilter = [...new Set([...options, ...this._WineFilterOptions])];

		options.map((newFilterOption) => {
			if (newFilterOption.PropertyName === "Color") {
				tempFilter = tempFilter.filter((filter) => filter.PropertyName !== "Color");
				tempFilter.push(newFilterOption);
			}
		});

		this._WineFilterOptions = tempFilter;
	}

	FilterWineList(wineList: Wine[], advancedFiltering = false): Wine[] {
		let filteredWineList = wineList;
		this.WineFilterOptions.forEach((filter) => {
			filteredWineList = filter.FilterWineList(filteredWineList);
		});

		if (filteredWineList.length < 5 || advancedFiltering === false) {
			let largerWineList = wineList;
			this.WineFilterOptions.forEach((filter) => {
				if(filter.PropertyName === "Id")
					largerWineList = filter.FilterWineList(largerWineList);
			});

			filteredWineList = largerWineList;
		}

		return filteredWineList;
	}
}

export class WineFilter {
	PropertyName: string;
	ValueToCompare: string;
	Operator: Operator;

	constructor(WineFilter: WineFilter) {
		this.PropertyName = WineFilter.PropertyName;
		this.ValueToCompare = WineFilter.ValueToCompare;
		this.Operator = WineFilter.Operator;
	}

	FilterWineList(wineList: Wine[]): Wine[] {
		return wineList.filter((wine) => {
			if (this.ValueToCompare === undefined) return true;

			switch (this.Operator) {
				case Operator.Equal:
					return wine[this.PropertyName] === this.ValueToCompare;
				case Operator.NotEqual:
					return wine[this.PropertyName] !== this.ValueToCompare;
				case Operator.GreaterThan:
					return wine[this.PropertyName] > this.ValueToCompare;
				case Operator.GreaterThanOrEqual:
					return wine[this.PropertyName] >= this.ValueToCompare;
				case Operator.LessThan:
					return wine[this.PropertyName] < this.ValueToCompare;
				case Operator.LessThanOrEqual:
					return wine[this.PropertyName] <= this.ValueToCompare;
				case Operator.Contains:
					return wine[this.PropertyName].includes(this.ValueToCompare);
				case Operator.IsNull:
					return wine[this.PropertyName] === null;
				case Operator.IsNotNull:
					return wine[this.PropertyName] !== null;
				case Operator.Regex:
					return new RegExp(this.ValueToCompare).test(wine[this.PropertyName]);
				default:
					return false;
			}
		});
	}
}

export enum Operator {
	Equal = "Equal",
	NotEqual = "NotEqual",
	GreaterThan = "GreaterThan",
	GreaterThanOrEqual = "GreaterThanOrEqual",
	LessThan = "LessThan",
	LessThanOrEqual = "LessThanOrEqual",
	Contains = "Contains",
	IsNull = "IsNull",
	IsNotNull = "IsNotNull",
	Regex = "Regex",
}
