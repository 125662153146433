
import { defineComponent, PropType } from "vue";
import Restaurant from "@/entities/domain/Restaurant/Restaurant";
import { Answer } from "@/entities/domain/Quiz/Answer";

export default defineComponent({
	name: "StartChoice",
	props: {
		restaurant: {
			type: Object as PropType<Restaurant>,
		},
	},
	emits: ["setAnswer"],
	computed: {
		baseComponentTranslations() {
			return this.restaurant?.baseComponentTranslations;
		},
		answers(): Answer[] {
			return this.restaurant?.Quiz.getCurrentQuestion().Answers ?? [];
		},
	},
	methods: {
		answerClicked(answer: Answer) {
			this.$emit("setAnswer", { answerId: answer.Id, answerValue: answer.Value });
		},
	},
});
