
import Restaurant from "@/entities/domain/Restaurant/Restaurant";
import { PropType } from "vue";

export default {
	name: "AGB",
	props: {
		restaurant: {
			type: Object as PropType<Restaurant>,
		},
	},
};
