
import { defineComponent, PropType } from 'vue'

import StartChoice from '@/components/Question/StartChoice.vue'
import Restaurant from '@/entities/domain/Restaurant/Restaurant'

export default defineComponent({
  name: 'QuizSelectionPage',
  components: {
    StartChoice
  },
  props: {
    restaurant: {
      type: Object as PropType<Restaurant>
    }
  }
})
