
import SinglePictureChoice from "@/components/Question/SinglePictureChoice.vue";
import { Question } from "@/entities/domain/Quiz/Question";
import Restaurant from "@/entities/domain/Restaurant/Restaurant";
import { Languages } from "@/entities/enums/QuizConfigEnums";
import { defineComponent, PropType } from "vue";

export default defineComponent({
	name: "LanguageSelectionPage",
	props: {
		restaurant: {
			type: Object as PropType<Restaurant>,
			required: true,
		},
	},
	components: { SinglePictureChoice },
	computed: {
		languageQuestion(): Question {
			return this.restaurant.getLanguageQuestion();
		},
	},
	methods: {
		setAnswer(e) {
			const selectedLanguage = this.restaurant.getLanguageQuestion().Answers[
				e.answerId
			].Text?.toUpperCase() as Languages;

			this.restaurant.setLanguage(selectedLanguage);

			this.$router.push("/question/" + this.restaurant.Quiz.getCurrentQuestion().Id);
		},
	},
	created() {
		if (this.restaurant.languageWasSelected) {
			this.restaurant.setLanguage(this.restaurant.selectedLanguage);
			this.$router.push("/question/" + this.restaurant.Quiz.getCurrentQuestion().Id);
		}
	},
});
