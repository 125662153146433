
import { defineComponent } from "vue";

export default defineComponent({
    name: "ThumbDown",
    props: {
        color: {
            type: String,
            default: "#FF0F00",
        },
    },
});
