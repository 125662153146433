
import Restaurant from "@/entities/domain/Restaurant/Restaurant";
import { defineComponent, PropType } from "vue";
import FlagWave from "../FlagWave.vue";

export default defineComponent({
	name: "TasteType",
	components: { FlagWave },
	props: {
		restaurant: {
			type: Object as PropType<Restaurant>,
			required: true,
		},
	},
	methods: {
		convertMarkdown(text: string) {
			const count = text.match(/(\*\*)/g);
			let result = text;
			if (count && count.length > 0) {
				for (let i = 0; i < count.length; i++) {
					result = result.replace("**", "<span style='font-weight: bold;'>");
					result = result.replace("**", "</span>");
				}
			}

			return result;
		},
	},
});
