
import FeedbackOverlay from "@/components/BaseComponents/FeedbackOverlay.vue";
import { Feedback } from "@/entities/domain/General/Feedback";
import { Wine } from "@/entities/domain/Match/Wine";
import Restaurant from "@/entities/domain/Restaurant/Restaurant";
import { Translations } from "@/entities/domain/Translations";
import {
  StylingVariant,
  WineButtonAction,
} from "@/entities/enums/QuizConfigEnums";
import QuizSelectionPage from "@/pages/QuizSelectionPage.vue";
import QuizService from "@/services/QuizService";
import { tsUndefinedKeyword } from "@babel/types";
import { WineFeedbackCause } from "@/entities/enums/WineFeedbackCause";

import { defineComponent, nextTick, PropType } from "vue";

export default defineComponent({

  name: "WineCard",
  components: {
    FeedbackOverlay
  },
  props: {
    wine: {
      type: Object as PropType<Wine>,
      required: true,
    },
    restaurant: {
      type: Object as PropType<Restaurant>,
      required: true,
    },
    stylingVariant: {
      type: String as PropType<StylingVariant>,
      default: StylingVariant.NORMAL,
    },
  },
  emits: ["wineImageClicked", "wineClicked", "wineFeedbackCauseSelected"],
  data() {
    return { isFeedbackOverlayVisible: false, favorite: false, dislike: false, btgButtonText: this.restaurant.DisplayedTexts["Verkostung starten"] };
  },

  async mounted() {
    await this.setBtgButtonText();
  },

  computed: {
    baseComponentTranslations(): Translations {
      return this.restaurant.baseComponentTranslations;
    },
    wineButtonText(): string {
      if (
        !this.restaurant.isIGetNowModeActive &&
        this.wine.Handle &&
        this.restaurant.RestaurantQuizConfig.EndscreenWineButtonAction !==
        WineButtonAction.SAVEMODE &&
        this.restaurant.RestaurantQuizConfig.EndscreenWineButtonAction !==
        WineButtonAction.OPENNAVIGATIONMAP &&
        this.restaurant.RestaurantQuizConfig.EndscreenWineButtonAction !==
        WineButtonAction.STARTTASTING &&
        this.restaurant.RestaurantQuizConfig.EndscreenWineButtonAction !==
        WineButtonAction.EXPLOREMODE &&
        this.restaurant.RestaurantQuizConfig.EndscreenWineButtonAction !==
        WineButtonAction.SHOWQRCODE
      )
        return this.isWineAvailable
          ? this.restaurant.DisplayedTexts["Jetzt kaufen"]
          : this.restaurant.DisplayedTexts["Nicht verfügbar"];
      else if (this.restaurant.isIGetNowModeActive)
        return this.isWineAvailable
          ? this.restaurant.DisplayedTexts["zur Karte"]
          : this.restaurant.DisplayedTexts["Nicht verfügbar"];
      else if (
        this.restaurant.RestaurantQuizConfig.EndscreenWineButtonAction ===
        WineButtonAction.OPENNAVIGATIONMAP
      )
        return this.isWineAvailable
          ? this.restaurant.DisplayedTexts["Navigation öffnen"]
          : this.restaurant.DisplayedTexts["Nicht verfügbar"];
      else if (
        this.restaurant.RestaurantQuizConfig.BTGMode
      )
        return (
          this.restaurant.DisplayedTexts["Verkostung starten"]
        );
      else if (
        this.restaurant.RestaurantQuizConfig.EndscreenWineButtonAction ===
        WineButtonAction.SHOWQRCODE
      )
        return this.restaurant.DisplayedTexts["Speichern"];
      else if(
        this.restaurant.RestaurantQuizConfig.EndscreenWineButtonAction ===
        WineButtonAction.EXPLOREMODE
      )
        return this.restaurant.DisplayedTexts["Jetzt Entdecken"];

      return this.restaurant.DisplayedTexts["Speichern"];
    },
    isWineAvailable(): boolean {
      return this.restaurant.isWineAvailable(this.wine);
    },
    dislikeFeedbackReasons(): Feedback[] {
      return [
        {
          Title: this.restaurant.DisplayedTexts["Weinfarbe"],
          Value: WineFeedbackCause.COLOR
        },
        {
          Title: this.restaurant.DisplayedTexts["Preis"],
          Value: WineFeedbackCause.PRICE
        },
        {
          Title: this.restaurant.DisplayedTexts["Geschmack"],
          Value: "taste",
          SubSelection: [
            {
              Title: this.restaurant.DisplayedTexts["Marke"],
              Value: WineFeedbackCause.TASTE_BRAND
            },
            {
              Title: this.restaurant.DisplayedTexts["Rebsorte"],
              Value: WineFeedbackCause.TASTE_GRAPE
            },
            {
              Title: this.restaurant.DisplayedTexts["Süßegrad"],
              Value: WineFeedbackCause.TASTE_SWEETNESS
            },
            {
              Title: this.restaurant.DisplayedTexts["Säuregehalt"],
              Value: WineFeedbackCause.TASTE_ACIDITY
            }
          ]
        },
        {
          Title: this.restaurant.DisplayedTexts["Etikett"],
          Value: "label",
          SubSelection: [
            {
              Title: this.restaurant.DisplayedTexts["Zu modern"],
              Value: WineFeedbackCause.LABEL_TOO_MODERN
            },
            {
              Title: this.restaurant.DisplayedTexts["Zu altbacken"],
              Value: WineFeedbackCause.LABEL_TOO_OLD_FASHIONED
            },
            {
              Title: this.restaurant.DisplayedTexts["Zu billig"],
              Value: WineFeedbackCause.LABLE_TOO_CHEAP
            },
            {
              Title: this.restaurant.DisplayedTexts["Zu kompliziert"],
              Value: WineFeedbackCause.LABLE_TOO_COMPLICATED
            }
          ]
        }
      ]
    }
  },
  methods: {
    sendFeedback(cause?) {

      if (cause === undefined)
        this.favorite = !this.favorite;

      if (cause === undefined && !this.favorite) return;

      if (cause !== undefined)
        this.dislike = !this.dislike;

      QuizService.sendEvent(JSON.stringify({ wine: this.wine.Id, wasGood: cause === undefined, cause: cause }), "WineFeedback");
      setTimeout(() => {
        this.isFeedbackOverlayVisible = false;
        if (cause !== undefined)
          this.$emit("wineFeedbackCauseSelected", { Wine: this.wine, Cause: cause } as { Wine: Wine, Cause: WineFeedbackCause })
      }, 2000)
    },
    async setBtgButtonText() {
      const gtins = await this.restaurant.getBtgGtins();
      if (this.restaurant.RestaurantQuizConfig.EndscreenWineButtonAction === WineButtonAction.STARTTASTING || (this.restaurant.RestaurantQuizConfig.BTGMode && gtins && gtins.includes(this.wine.GTIN)))
        this.btgButtonText = this.restaurant.DisplayedTexts["Verkostung starten"];
      else
        this.btgButtonText = this.restaurant.DisplayedTexts["Navigation öffnen"];
    }
  }
});
