
import { defineComponent, PropType } from "vue";

import Restaurant from "@/entities/domain/Restaurant/Restaurant";

import getImageFromSource from "@/utils/getImageFromSouce";
import addToStyling from "@/utils/AddToStyling";
import ChatbotWindow from "@/components/Elements/Chatbot/ChatbotWindow.vue";
import ChatbotWindowV3 from "@/components/Elements/Chatbot/ChatbotWindowV3.vue";

export default defineComponent({
	name: "ChatBotModePage",
	props: {
		restaurant: {
			type: Object as PropType<Restaurant>,
			required: true,
		},
	},
	data() {
		return {
			showBubble: false,
			bubbleWasOpened: false,
			focus: false,
			quizNeedsRefresh: false
		};
	},
	computed: {
		williTexts() {
			return this.restaurant?.RestaurantQuizConfig.WilliAsFeature;
		},
		open(): boolean {
			return this.restaurant?.RestaurantQuizConfig.WilliAsFeature?.RunInFeatureMode ?? false;
		},
	},
	watch: {
		showBubble: {
			handler(current: boolean) {
				addToStyling(`
					.williWein .tooltip::after{
						background-image: url("img/bubblearrow.svg");
					}
				`);
				if (current) this.bubbleWasOpened = true;
			},
			deep: true,
		},
	},
	mounted(){
		if(!this.restaurant.RestaurantQuizConfig.WilliAsFeature?.RunInFeatureMode)
			this.$router.push("/question/" + this.restaurant.Quiz.QuestionIdHistory[0]);

		setTimeout(() => {
			if (!this.showBubble && !this.bubbleWasOpened && this.restaurant.RestaurantQuizConfig.WilliAsFeature?.RunInFeatureMode && this.restaurant.RestaurantQuizConfig.WilliAsFeature?.SetNoticeAutomatically) {
				this.showBubble = true;

				if(this.restaurant.RestaurantQuizConfig.WilliAsFeature.SetFocusAutomatically) this.focus = true;
			}
		}, 5000);

		// if Quiz is closed longer than thirty seconds, refresh quiz when opened
		setTimeout(() => {
			if (this.restaurant.isQuizClosed) this.quizNeedsRefresh = true;
		}, 30000);
		
		addToStyling(`
			.williWein .tooltip::after{
				background-image: url("img/bubblearrow.svg");
			}
		`);
	},
	methods: {
		getImageFromSource,
		onFeatureWilliClick(): void {
			// TODO: Implement
		},
	},
	components: { ChatbotWindow, ChatbotWindowV3 },
});
