
import Restaurant from "@/entities/domain/Restaurant/Restaurant";
import getImageFromSource from "@/utils/getImageFromSouce";
import { defineComponent, PropType } from "vue";
import HeaderWave from "./HeaderWave.vue";

export default defineComponent({
	name: "HeaderComponent",
	components: { HeaderWave },
	props: {
		restaurant: {
			type: Object as PropType<Restaurant>,
			default: {} as Restaurant,
		},
	},
	computed: {
		brandLogoImageSource() {
			return getImageFromSource(
				this.restaurant?.FrontendDesign?.BrandLogoImage ??
					"@/assets/img/logo_tailorwine.svg"
			);
		},
	},
});
