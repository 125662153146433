// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./LidlFontCondPro-Regular.woff2");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./LidlFontCondPro-Bold.woff2");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./LidlFontCondPro-BoldItalic.woff2");
var ___CSS_LOADER_URL_IMPORT_3___ = require("./LidlFontCondPro-Book.woff2");
var ___CSS_LOADER_URL_IMPORT_4___ = require("./LidlFontCondPro-BookItalic.woff2");
var ___CSS_LOADER_URL_IMPORT_5___ = require("./LidlFontCondPro-Italic.woff2");
var ___CSS_LOADER_URL_IMPORT_6___ = require("./LidlFontCondPro-Semibold.woff2");
var ___CSS_LOADER_URL_IMPORT_7___ = require("./LidlFontCondPro-SemiboldItalic.woff2");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
// Module
exports.push([module.id, "@font-face{font-family:Lidl Cond Pro;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\");font-weight:400;font-style:normal}@font-face{font-family:Lidl Cond Pro;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff2\");font-weight:700;font-style:normal}@font-face{font-family:Lidl Cond Pro;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff2\");font-weight:700;font-style:italic}@font-face{font-family:Lidl Cond Pro;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff2\");font-weight:400;font-style:normal}@font-face{font-family:Lidl Cond Pro;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"woff2\");font-weight:400;font-style:italic}@font-face{font-family:Lidl Cond Pro;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format(\"woff2\");font-weight:400;font-style:italic}@font-face{font-family:Lidl Cond Pro;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ") format(\"woff2\");font-weight:semibold;font-style:normal}@font-face{font-family:Lidl Cond Pro;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + ") format(\"woff2\");font-weight:semibold;font-style:italic}", ""]);
// Exports
module.exports = exports;
