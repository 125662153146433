
import { defineComponent, PropType } from "vue";
import Restaurant from "@/entities/domain/Restaurant/Restaurant";
import { Answer } from "@/entities/domain/Quiz/Answer";

export default defineComponent({
	name: "MultipleChoice",
	props: {
		restaurant: {
			type: Object as PropType<Restaurant>,
		},
	},
	emits: ["answerClicked"],
	data() {
		return { selectedAnswerIds: [] as number[], showInfoText: false };
	},
	computed: {
		answers(): Answer[] {
			return this.restaurant?.Quiz.getCurrentQuestion().Answers ?? [];
		},
	},
	methods: {
		answerClicked(answer: Answer) {
			const foundIndex = this.selectedAnswerIds.indexOf(answer.Id);
			if (foundIndex === -1) this.selectedAnswerIds.push(answer.Id);
			// Add Answer when not already present in Array
			else this.selectedAnswerIds.splice(foundIndex, 1); // Remove Answer from Array

			const currentScores = this.answers
				.filter((answer) => this.selectedAnswerIds.indexOf(answer.Id) !== -1)
				.map((answer) => Number(answer.Score));

			this.$emit("answerClicked", {
				answerId: answer.Id,
				answerValue: currentScores,
			});
		},
	},
});
