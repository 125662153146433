import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-649d469d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "headerWave" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderWave = _resolveComponent("HeaderWave")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_HeaderWave, {
        "base-color": _ctx.restaurant?.FrontendDesign?.PrimaryColor
      }, null, 8, ["base-color"])
    ]),
    (!_ctx.restaurant?.homepageMode)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: _ctx.brandLogoImageSource,
          class: "logo"
        }, null, 8, _hoisted_3))
      : _createCommentVNode("", true)
  ]))
}