
import Restaurant from "@/entities/domain/Restaurant/Restaurant";
import { QuestionType } from "@/entities/enums/QuizEnums";
import { defineComponent, PropType } from "vue";

export default defineComponent({
	name: "ProgressBar",
	props: {
		restaurant: Object as PropType<Restaurant>,
	},
	computed: {
		progress() {
			const noQuestionCount = this.restaurant?.Quiz.Questions.filter(question => this.restaurant?.Quiz.QuestionIdHistory.includes(question.Id) && question.Type === QuestionType.IntermediateScreenWaves).length ?? 0;

			const questionCount = this.restaurant?.Quiz?.QuestionIdHistory?.length ?? 0

			if(questionCount > 2)
				return questionCount - noQuestionCount;
			else
				return questionCount ?? 0;
		},
		overall() {
			if(this.restaurant?.RestaurantQuizConfig?.QuizCountCorrection)
				return this.restaurant?.Quiz.getQuestionCount() - this.restaurant?.RestaurantQuizConfig?.QuizCountCorrection  ?? 0;
			
			return this.restaurant?.Quiz.getQuestionCount() ?? 0;
		},
	},
});
