
import { defineComponent, PropType } from "vue";
import SingleChoice from "@/components/Question/SingleChoice.vue";
import MultipleChoice from "@/components/Question/MultipleChoice.vue";
import MultiplePictureChoice from "@/components/Question/MultiplePictureChoice.vue";
import BinaryChoice from "@/components/Question/BinaryChoice.vue";
import StartChoice from "@/components/Question/StartChoice.vue";
import SwipeChoice from "@/components/Question/SwipeChoice.vue";
import ProfileChoice from "@/components/Elements/Result/ProfileChoice.vue";
import SteplessSliderChoice from "@/components/Question/SteplessSliderChoice.vue";
import SingleChipSelection from "@/components/Question/SingleChipSelection.vue";
import MultipleChipSelection from "@/components/Question/MultipleChipSelection.vue";
import IntermediateScreenWaves from "@/components/Question/IntermediateScreenWaves.vue";
import VersusChoice from "@/components/Question/VersusChoice.vue";
import EmailChoice from "@/components/Question/EmailChoice.vue";
import SinglePictureChoice from "@/components/Question/SinglePictureChoice.vue";
import NumberInput from "@/components/Question/NumberInput.vue";
import QuizRestart from "@/components/Question/QuizRestart.vue";
import Restaurant from "@/entities/domain/Restaurant/Restaurant";

export default defineComponent({
	name: "Question",
	components: {
		SingleChoice,
		MultipleChoice,
		MultiplePictureChoice,
		BinaryChoice,
		StartChoice,
		SwipeChoice,
		ProfileChoice,
		SteplessSliderChoice,
		SingleChipSelection,
		MultipleChipSelection,
		IntermediateScreenWaves,
		VersusChoice,
		EmailChoice,
		SinglePictureChoice,
		NumberInput,
		QuizRestart,
	},
	props: {
		restaurant: {
			type: Object as PropType<Restaurant>,
		},
	},
	emits: ["answerClicked", "setAnswer", "restartQuiz", "closeQuiz"],
	methods: {
		async setAnswer(event) {
			await this.restaurant?.setAnswerAsync(event);
		},
		onAnswerClicked(event) {
			this.restaurant?.onAnswerClicked(event);
		},
	},
});
