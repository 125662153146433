import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2641dd33"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "EmailChoice" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EmailForm = _resolveComponent("EmailForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_EmailForm, {
      restaurant: _ctx.restaurant,
      infoText: _ctx.infoText
    }, null, 8, ["restaurant", "infoText"])
  ]))
}