import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5f155f30"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "multiselect" }
const _hoisted_2 = {
  key: 0,
  class: "options"
}
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      type: "text",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
      onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.filterOptions && _ctx.filterOptions(...args))),
      onFocus: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showOptions = true)),
      onBlur: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.hideOptions && _ctx.hideOptions(...args))),
      class: "input-field",
      placeholder: "Suche ..."
    }, null, 544), [
      [_vModelText, _ctx.search]
    ]),
    (_ctx.showOptions)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredOptions, (option) => {
            return (_openBlock(), _createElementBlock("div", {
              key: option,
              onClick: ($event: any) => (_ctx.selectOption(option))
            }, _toDisplayString(option), 9, _hoisted_3))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}