import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b2b5f364"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "question" }
const _hoisted_2 = { class: "explanation" }
const _hoisted_3 = {
  key: 0,
  class: "text"
}
const _hoisted_4 = {
  key: 0,
  class: "picture"
}
const _hoisted_5 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.restaurant?.Quiz.getCurrentQuestion().Explanation), 1),
    (
				_ctx.restaurant?.Quiz.getCurrentQuestion().Type !== 'ProfileChoice' &&
					_ctx.restaurant?.Quiz.getCurrentQuestion().Type !== 'StartChoice'
			)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.restaurant?.Quiz.getCurrentQuestion().Text), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", null, [
      (_ctx.restaurant?.Quiz.getCurrentQuestion().Picture)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("img", {
              src: _ctx.restaurant?.Quiz.getCurrentQuestion().Picture ?? undefined,
              alt: _ctx.restaurant?.Quiz.getCurrentQuestion().Text ?? 'Questionpicture'
            }, null, 8, _hoisted_5)
          ]))
        : _createCommentVNode("", true)
    ]),
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.restaurant?.Quiz.getCurrentQuestion().Type), {
      class: _normalizeClass(["component", {'startChoice': _ctx.restaurant?.Quiz.getCurrentQuestion().Type === 'StartChoice'}]),
      restaurant: _ctx.restaurant,
      onAnswerClicked: _ctx.onAnswerClicked,
      onSetAnswer: _ctx.setAnswer,
      onRestartQuiz: _cache[0] || (_cache[0] = ($event: any) => (_ctx.restaurant?.restartQuiz())),
      onCloseQuiz: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('closeQuiz')))
    }, null, 40, ["class", "restaurant", "onAnswerClicked", "onSetAnswer"]))
  ]))
}