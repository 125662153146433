export class AnswerForMatching {
	QuestionId: number;
	AnswerValue?: string;
	AnswerId: number;
	AnswerScore?: number | null;

	constructor(answerForMatching: AnswerForMatching) {
		this.QuestionId = answerForMatching.QuestionId;
		this.AnswerValue = answerForMatching.AnswerValue;
		this.AnswerId = answerForMatching.AnswerId;
		this.AnswerScore = answerForMatching.AnswerScore;
	}
}
