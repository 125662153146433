export class OperationResult<T>{
    public Success: boolean;
    public ErrorMessage?: string;
    public Data?: T;

    constructor(operationResult: OperationResult<T>){
        this.Success = operationResult.Success ?? false;
        this.ErrorMessage = operationResult.ErrorMessage;
        this.Data = operationResult.Data;
    }
}