interface optionType {
	header: boolean;
}

export default function AddToStyling(css: string, options?: optionType): void {
	const style = document.createElement("style");

	if (process.env.NODE_ENV === "development" || options?.header) {
		document.head.appendChild(style);
	} else {
		const shadowWilli = document
			.getElementById("willi-wein")
			?.shadowRoot?.getElementById("willi-wein");

		shadowWilli?.appendChild(style);
	}

	style.type = "text/css";
	style.appendChild(document.createTextNode(css));
}
