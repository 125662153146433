import { ProductLocation } from "./ProductLocation";
import { IWineResponse } from "@/interfaces/api/responses/Matching/IWineResponse";

export class Wine {
	Id: string;
	Name: string;
	Description: string;
	FoodRecommendation: string;
	Grape: string;
	GTIN?: string;
	Phonetic: string;
	Region: string;
	ContentInMl?: string;
	Country: string;
	Category: string;
	Color: string;
	TasteMatch: number;
	// MealMatch: MealMatch[]; // TODO: Check if this really doesnt come in response
	MealMatch: number;
	AromaMatch: number;
	IgnId: string | null;
	IgnCategory: string | null;
	Handle: string;
	PitchNumber: string;
	ProductLocation?: ProductLocation;
	PictureFront: string;
	Price?: number;
	OfferPrice?: number;
	AvailableBybottle?: boolean;
	AvailableByglass?: boolean;

	constructor(wineType: Wine) {
		this.Id = wineType.Id;
		this.Name = wineType.Name;
		this.Description = wineType.Description;
		this.FoodRecommendation = wineType.FoodRecommendation;
		this.Grape = wineType.Grape;
		this.GTIN = wineType.GTIN;
		this.Phonetic = wineType.Phonetic;
		this.Region = wineType.Region;
		this.ContentInMl = wineType.ContentInMl;
		this.Country = wineType.Country;
		this.Category = wineType.Category;
		this.Color = wineType.Color;
		this.TasteMatch = wineType.TasteMatch;
		this.MealMatch = wineType.MealMatch;
		this.AromaMatch = wineType.AromaMatch;
		this.IgnId = wineType.IgnId;
		this.IgnCategory = wineType.IgnCategory;
		this.Handle = wineType.Handle;
		this.PitchNumber = wineType.PitchNumber;
		this.ProductLocation = wineType.ProductLocation;
		this.PictureFront = wineType.PictureFront;
		this.Price = wineType.Price;
		this.OfferPrice = wineType.OfferPrice;
		this.AvailableBybottle = wineType.AvailableBybottle;
		this.AvailableByglass = wineType.AvailableByglass;
	}

	static createFromApiResponse(response: IWineResponse): Wine {
		const newWineType = {
			Id: response.id,
			Name: response.name,
			Description: response.description,
			FoodRecommendation: response.food_recommendation,
			Grape: response.grape,
			GTIN: response.GTIN,
			Phonetic: response.phonetic,
			Region: response.region,
			ContentInMl: response.content_ml,
			Country: response.country,
			Category: response.category,
			Color: response.color,
			TasteMatch: response.tasteMatch,
			// MealMatch: response.mealMatch?.map((mealMatch) =>
			// 	MealMatch.createFromApiResponse(mealMatch)
			// ),
			MealMatch: response.mealMatch,
			AromaMatch: response.aromaMatch,
			IgnId: response.ign_id,
			IgnCategory: response.ign_category,
			Handle: response.handle,
			PitchNumber: response.pitch_number,
			ProductLocation: response.product_location && ProductLocation.createFromApiResponse(response.product_location),
			PictureFront: response.picture_front,
			Price: response.price,
			OfferPrice: response.offer_price,
			AvailableBybottle: response.bybottle,
			AvailableByglass: response.byglass,
		} as Wine;

		return newWineType;
	}
}
