import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-503c3564"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "SwipeChoice" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.answers, (answer) => {
      return (_openBlock(), _createElementBlock("button", {
        key: answer.Id,
        class: "button",
        onClick: ($event: any) => (_ctx.$emit('answerClicked', answer.Score))
      }, _toDisplayString(answer.Text), 9, _hoisted_2))
    }), 128))
  ]))
}