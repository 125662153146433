import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a10571e8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "VersusChoice" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "textAnswer" }
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultiplePictureChoiceButton = _resolveComponent("MultiplePictureChoiceButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.answers, (answer) => {
      return (_openBlock(), _createElementBlock(_Fragment, {
        key: answer.Id
      }, [
        (answer.Picture)
          ? (_openBlock(), _createBlock(_component_MultiplePictureChoiceButton, {
              key: 0,
              class: "buttonComponent",
              versusMode: "",
              answer: answer,
              "show-caption": true,
              selectedAnswer: _ctx.selectedAnswerId === answer.Id,
              onClick: ($event: any) => (_ctx.answerClicked(answer)),
              textColor: _ctx.restaurant?.RestaurantQuizConfig.DarkMode ? '#ffffff' : '#757575'
            }, null, 8, ["answer", "selectedAnswer", "onClick", "textColor"]))
          : (_openBlock(), _createElementBlock("div", {
              key: 1,
              onClick: ($event: any) => (_ctx.answerClicked(answer)),
              style: _normalizeStyle('color:' + _ctx.restaurant?.FrontendDesign?.BackgroundColor?.toLowerCase() === '#1e1e1e' ? '#ffffff' : '#757575')
            }, [
              _createElementVNode("h1", _hoisted_3, _toDisplayString(answer.Text), 1)
            ], 12, _hoisted_2)),
        (answer.Id !== _ctx.answers[_ctx.answers.length - 1].Id)
          ? (_openBlock(), _createElementBlock("div", {
              key: 2,
              class: _normalizeClass(["versusChoiceImage", { noImage: !answer.Picture }])
            }, [
              _createElementVNode("img", {
                src: _ctx.getThumbImage()
              }, null, 8, _hoisted_4)
            ], 2))
          : _createCommentVNode("", true)
      ], 64))
    }), 128))
  ]))
}