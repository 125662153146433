import { IQuestionFrontendSettingResponse } from "@/interfaces/api/responses/IQuestionFrontendSettingResponse";

export class QuestionFrontendSetting {
    Key: string;
    Value: string;

    constructor(questionFrontendSetting: QuestionFrontendSetting) {
        this.Key = questionFrontendSetting.Key;
        this.Value = questionFrontendSetting.Value;
    }

    static createFromApiResponse(response: IQuestionFrontendSettingResponse): QuestionFrontendSetting {
        const newQuestionFrontendSetting = new QuestionFrontendSetting({
            Key: response.key,
            Value: response.value,
        });

        return newQuestionFrontendSetting;
    }

}