
import { defineComponent } from "vue";

export default defineComponent({
    name: "Cutlery",
    props: {
        color: {
            type: String,
            default: "#333333",
        },
    },
});
