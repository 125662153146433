
import { defineComponent, PropType } from "vue";
import InfoWilli from "@/components/Elements/InfoWilli.vue";
import ImageClickPosition from "@/components/Elements/ImageClickPosition.vue";

import anime from "animejs";

import addToStyling from "@/utils/AddToStyling";
import Restaurant from "@/entities/domain/Restaurant/Restaurant";
import { Answer } from "@/entities/domain/Quiz/Answer";

export default defineComponent({
	name: "SteplessSlider",
	components: { ImageClickPosition, InfoWilli },
	props: {
		restaurant: {
			type: Object as PropType<Restaurant>,
		},
	},
	emits: ["answerClicked"],
	data() {
		return {
			sliderValue: 50,
			sliderRange: 100,
			giveInfo: undefined as number | undefined,
			showInfoText: false,
		};
	},
	computed: {
		answers(): Answer[] {
			return this.restaurant?.Quiz.getCurrentQuestion().Answers ?? [];
		},
		baseComponentTranslations() {
			return this.restaurant?.baseComponentTranslations;
		},
		currentAnswer() {
			const answerCount = this.answers?.length ?? 1;
			const answerRange = this.sliderRange / answerCount;

			if (this.sliderValue == this.sliderRange) return this.answers.length - 1;

			return Math.floor(this.sliderValue / answerRange);
		},
		gradiantSliderValue() {
			if (this.sliderValue > 15 && this.sliderValue < 85) return this.sliderValue;

			return this.sliderValue > 15
				? Number(this.sliderValue) - 5
				: Number(this.sliderValue) + 5;
		},
	},
	watch: {
		answers: {
			handler() {
				this.sliderValue = 50;
				this.$emit("answerClicked", {
					answerId: this.answers[this.currentAnswer].Id,
					answerValue: this.sliderValue,
				});

				if (this.giveInfo) {
					clearInterval(this.giveInfo);
					this.giveInfo = undefined;
				}

				this.giveInfo = setInterval(() => {
					this.showInfoText = true;
				}, 10000);
			},
			deep: true,
		},
		sliderValue() {
			if (this.giveInfo) {
				clearInterval(this.giveInfo);
				this.giveInfo = undefined;
			}

			this.giveInfo = setInterval(() => {
				this.showInfoText = true;
			}, 10000);
		},
	},
	mounted() {
		if (!sessionStorage.getItem("infoWilliSlider")) {
			this.giveInfo = setInterval(() => {
				this.giveInfoAboutUsage(this.sliderValue);
				this.showInfoText = true;
			}, 5000);
		}

		this.$emit("answerClicked", {
			answerId: this.answers ? this.answers[this.currentAnswer].Id : null,
			answerValue: this.sliderValue,
		});

		addToStyling(`
					
					#tw-slider::-webkit-slider-thumb {
						background: url("${this.restaurant?.FrontendDesign?.SliderThumbImage ??
							process.env.VUE_APP_FILE_SPACE + "img/tailorwine_slider_button.svg"}");
						-webkit-appearance: none; /* Override default look */
						appearance: none;
						width: 65px; /* Set a specific slider handle width */
						height: 65px; /* Slider handle height */
						border: none;
						background-size: contain;
						background-position: center center;
						background-repeat: no-repeat;
						cursor: pointer; /* Cursor on hover */
						// padding: 20px;
						// border-radius: 20px;
						padding: auto;
						border-radius: 65px;
					}
					
					#tw-slider::-moz-range-thumb {
						background: url("${this.restaurant?.FrontendDesign?.SliderThumbImage ??
							process.env.VUE_APP_FILE_SPACE + "img/tailorwine_slider_button.svg"}");
						-webkit-appearance: none; /* Override default look */
						appearance: none;
						width: 65px; /* Set a specific slider handle width */
						height: 65px; /* Slider handle height */
						border: none;
						background-size: contain;
						background-position: center center;
						background-repeat: no-repeat;
						cursor: pointer; /* Cursor on hover */
						// padding: 20px;
						// border-radius: 20px;
						padding: auto;
						border-radius: 65px;
					}
					`);
	},

	methods: {
		giveInfoAboutUsage(innitVal: number) {
			const obj = { n: innitVal };
			anime({
				targets: obj,
				n: [innitVal + 5, innitVal - 5, innitVal + 5, innitVal - 5, innitVal],
				duration: 1500,
				easing: "linear",
				update: () => {
					this.sliderValue = obj.n;
				},
			});
		},
		infoAcknowledged() {
			clearInterval(this.giveInfo);
			this.showInfoText = false;
			this.giveInfo = undefined;

			sessionStorage.setItem("infoWilliSlider", "acknowledged");
		},
		setValsFromImageClick(data) {
			const answerCount = this.answers?.length ?? 1;

			const calcVal = data.posX / (data.width / answerCount);

			this.sliderValue = Math.floor((this.sliderRange / answerCount) * calcVal);
		},
	},
});
