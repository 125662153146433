import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a313cfe4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "loading"
}
const _hoisted_2 = {
  key: 0,
  class: "lds-dual-ring"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "restartInfo" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.loading && !_ctx.loadingTimeOut)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2))
          : (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createTextVNode(_toDisplayString(_ctx.restaurant.DisplayedTexts["Beim Laden der Daten ist ein Fehler aufgetreten. Bitte versuche es erneut."]) + " ", 1),
                _createElementVNode("button", {
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.reloadPage && _ctx.reloadPage(...args)))
                }, _toDisplayString(_ctx.restaurant.DisplayedTexts["Neustart"]), 1)
              ])
            ]))
      ]))
    : _createCommentVNode("", true)
}