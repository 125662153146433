
import { Question } from "@/entities/domain/Quiz/Question";
import Restaurant from "@/entities/domain/Restaurant/Restaurant";
import { Translations } from "@/entities/domain/Translations";
import getImageFromSource from "@/utils/getImageFromSouce";
import { defineComponent, PropType } from "vue";
import AGB from "../AGB.vue";
import DataPrivacy from "../DataPrivacy.vue";
import DialogComponent from "../Dialog.vue";
import FooterWave from "./FooterWave.vue";

export default defineComponent({
	name: "FooterComponent",
	components: {
		FooterWave,
		AGB,
		DataPrivacy,
		DialogComponent,
	},
	props: {
		restaurant: {
			type: Object as PropType<Restaurant>,
		},
	},
	data() {
		return {
			openDialogFor: null as null | string,
		};
	},
	computed: {
		currentQuestion(): Question {
			if(!this.restaurant?.Quiz.getCurrentQuestion) return {} as Question;

			return this.restaurant?.Quiz.getCurrentQuestion() ?? ({} as Question);
		},
		baseComponentTranslations(): Translations {
			return this.restaurant?.baseComponentTranslations ?? {};
		},
	},
	methods: {
		getImageFromSource,
	},
});
