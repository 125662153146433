<!-- eslint-disable vue/multi-word-component-names -->
<template>
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 246.34 190.6">
		<g id="dunkle_früchte" data-name="dunkle früchte">
			<path
				class="cls-1"
				d="M232.72,68.31V167.4a36.52,36.52,0,0,1-1.88,5.84c-3.9,7.6-10.17,11.31-18.78,11.3q-88.6,0-177.23,0c-12.6,0-20.78-8.24-20.79-20.89V68.31l1.06.91c4.27,3.53,8.33,7.36,12.84,10.53q32.73,23,65.7,45.7a121.67,121.67,0,0,0,15.5,8.86,30.44,30.44,0,0,0,21.18,2.51,59.2,59.2,0,0,0,19.46-8.94q28.59-19.83,57.08-39.79C215.76,81.87,225.2,76.34,232.72,68.31Z"
			></path>
			<path
				class="cls-1"
				d="M232.72,29.87V35a39.61,39.61,0,0,1-4.07,12.47c-4.49,7.9-10.79,14.05-18.19,19.19q-32.49,22.61-65,45.23c-3.79,2.62-7.75,5-11.78,7.28-6.87,3.84-13.87,3.78-20.75,0a120.52,120.52,0,0,1-10.37-6.32C80.68,97.6,58.86,82.27,37,67.06A69.18,69.18,0,0,1,21.2,52.17c-5.6-7.33-8.85-15.26-6.2-24.63,1.92-6.81,5.56-12.21,12.85-14a31.11,31.11,0,0,1,7.41-.71q88.07-.06,176.13,0c1.21,0,2.42,0,3.63.12a18.88,18.88,0,0,1,10.29,4.21C229.47,20.4,231.67,24.79,232.72,29.87Z"
			></path>
		</g>
	</svg>
</template>
<style scoped lang="scss">
.cls-1 {
	fill: var(--primary-color);
}
</style>
