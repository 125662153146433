import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "15",
  height: "15",
  viewBox: "0 0 15 15",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_2 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      d: "M10.6667 3.605C10.6667 5.00625 10.0017 6.17792 9.08333 6.62917V14.625H7.5V6.62917C6.58167 6.17792 5.91667 5.00625 5.91667 3.605C5.92458 1.82375 6.98542 0.375 8.29167 0.375C9.60583 0.375 10.6667 1.82375 10.6667 3.605ZM11.4583 0.375V14.625H13.0417V8.29167H14.625V3.54167C14.625 2.14833 13.6433 0.375 11.4583 0.375ZM4.555 0.375C4.23833 0.375 3.985 0.628333 3.985 0.945V3.54167H3.32V0.945C3.32 0.628333 3.06667 0.375 2.75 0.375C2.43333 0.375 2.18 0.628333 2.18 0.945V3.54167H1.515V0.945C1.515 0.628333 1.26167 0.375 0.945 0.375C0.628333 0.375 0.375 0.628333 0.375 0.945V5.125C0.375 5.99583 1.0875 6.70833 1.95833 6.70833V14.625H3.54167V6.70833C4.4125 6.70833 5.125 5.99583 5.125 5.125V0.945C5.125 0.628333 4.87167 0.375 4.555 0.375Z",
      fill: _ctx.color
    }, null, 8, _hoisted_2)
  ]))
}