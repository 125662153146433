import { IFrontendDesignResponse } from "@/interfaces/api/responses/IFrontendDesignResponse";
import { IWineWizardStylResponse } from "@/interfaces/api/responses/IWineWizardStylResponse";
import { ChatbotTextConventions } from "./ChatbotTextConventions";
import { WineCardVersion } from "@/entities/enums/QuizConfigEnums";

export class FrontendDesign {
	Id?: number;
	PrimaryColor?: string;
	SecondaryColor?: string;
	BackgroundColor?: string;
	SecondaryLightColor?: string;
	PrimaryFontColor?: string;
	SecondaryFontColor?: string;
	HoverBlobColor?: string;
	DisabledButtonBackgroundColor?: string;
	ProgressBarBackgroundColor?: string;
	FancyButtonWaveEndColor?: string;
	DisabledButtonFontColor?: string;
	SteplessSliderBackgroundColor?: string;

	SmartphoneImage?: string;
	SliderThumbImage?: string;
	VsThumbImage?: string;
	WilliImage?: string;
	BrandLogoImage?: string;
	IntermediateScreenOneImage?: string;
	IntermediateScreenTwoImage?: string;
	IntermediateScreenThreeImage?: string;

	PrimaryFontFamily?: string;
	SecondaryFontFamily?: string;
	PrimaryFontWeight?: string;
	SecondaryFontWeight?: string;

	WineCardVersion: WineCardVersion;

	WineWizard?: WineWizardStyle;

	constructor(frontendDesign: FrontendDesign) {
		this.Id = frontendDesign.Id;

		// Colors
		this.PrimaryColor = frontendDesign.PrimaryColor;
		this.SecondaryColor = frontendDesign.SecondaryColor;
		this.BackgroundColor = frontendDesign.BackgroundColor;
		this.SecondaryLightColor = frontendDesign.SecondaryLightColor;
		this.PrimaryFontColor = frontendDesign.PrimaryFontColor;
		this.SecondaryFontColor = frontendDesign.SecondaryFontColor;
		this.HoverBlobColor = frontendDesign.HoverBlobColor;
		this.DisabledButtonBackgroundColor = frontendDesign.DisabledButtonBackgroundColor;
		this.ProgressBarBackgroundColor = frontendDesign.ProgressBarBackgroundColor;
		this.FancyButtonWaveEndColor = frontendDesign.FancyButtonWaveEndColor;
		this.DisabledButtonFontColor = frontendDesign.DisabledButtonFontColor;
		this.SteplessSliderBackgroundColor = frontendDesign.SteplessSliderBackgroundColor;

		// Images
		this.SmartphoneImage = frontendDesign.SmartphoneImage;
		this.SliderThumbImage = frontendDesign.SliderThumbImage;
		this.VsThumbImage = frontendDesign.VsThumbImage;
		this.WilliImage = frontendDesign.WilliImage;
		this.BrandLogoImage = frontendDesign.BrandLogoImage;
		this.IntermediateScreenOneImage = frontendDesign.IntermediateScreenOneImage;
		this.IntermediateScreenTwoImage = frontendDesign.IntermediateScreenTwoImage;
		this.IntermediateScreenThreeImage = frontendDesign.IntermediateScreenThreeImage;

		// Fonts
		this.PrimaryFontFamily = frontendDesign.PrimaryFontFamily;
		this.SecondaryFontFamily = frontendDesign.SecondaryFontFamily;
		this.PrimaryFontWeight = frontendDesign.PrimaryFontWeight;
		this.SecondaryFontWeight = frontendDesign.SecondaryFontWeight;

		this.WineCardVersion = frontendDesign.WineCardVersion;

		// WineWizard
		this.WineWizard = frontendDesign.WineWizard;
	}

	static createFromApiResponse(response?: IFrontendDesignResponse | null): FrontendDesign | null {
		if (!response) return null;

		const newFrontendDesign = new FrontendDesign({
			Id: response.id ?? undefined,
			PrimaryColor: response.primary_color ?? undefined,
			SecondaryColor: response.secondary_color ?? undefined,
			BackgroundColor: response.background_color ?? undefined,
			SecondaryLightColor: response.secondary_color_light ?? undefined,
			PrimaryFontColor: response.primary_font_color ?? undefined,
			SecondaryFontColor: response.secondary_font_color ?? undefined,
			HoverBlobColor: response.hover_blob_color ?? undefined,
			DisabledButtonBackgroundColor: response.disabled_button_background ?? undefined,
			ProgressBarBackgroundColor: response.progress_bar_background ?? undefined,
			FancyButtonWaveEndColor: response.fancy_button_stop ?? undefined,
			DisabledButtonFontColor: response.disabled_button_font ?? undefined,
			SteplessSliderBackgroundColor: response.stepless_slider_background ?? undefined,
			SmartphoneImage: response.smartphone ?? undefined,
			SliderThumbImage: response.slider_thumb ?? undefined,
			VsThumbImage: response.vs_thumb ?? undefined,
			WilliImage: response.willi ?? undefined,
			BrandLogoImage: response.brand_logo ?? undefined,
			IntermediateScreenOneImage: response.intermediate_screen_one ?? undefined,
			IntermediateScreenTwoImage: response.intermediate_screen_two ?? undefined,
			IntermediateScreenThreeImage: response.intermediate_screen_three ?? undefined,
			PrimaryFontFamily: response.primary_font_family ?? undefined,
			SecondaryFontFamily: response.secondary_font_family ?? undefined,
			PrimaryFontWeight: response.primary_font_weight ?? undefined,
			SecondaryFontWeight: response.secondary_font_weight ?? undefined,
			WineCardVersion: response.wine_card_version as WineCardVersion ?? WineCardVersion.FIRST,
			WineWizard: WineWizardStyle.createFromApiResponse(response.WineWizardStyle) ?? undefined
		});

		return newFrontendDesign;
	}
}

export class WineWizardStyle{
	Id: number;
	FirstColorWave?: string;
	SecondColorWave?: string;
	PrimaryColor?: string;
	SecondaryColor?: string;
	FontColorPrimary?: string;
	FontColorSecondary?: string;
	FontColorButton?: string;
	BackgroundColorListSelection?: string;

	AvatarImageUrl?: string;
	AvatarCentered?: boolean;

	TextConventions?: ChatbotTextConventions;

	Bottom?: string;
	Right?: string;

	IconBackgroundColor?: string;

	constructor(wineWizardStyle: WineWizardStyle) {
		this.Id = wineWizardStyle.Id;
		this.FirstColorWave = wineWizardStyle.FirstColorWave;
		this.SecondColorWave = wineWizardStyle.SecondColorWave;
		this.PrimaryColor = wineWizardStyle.PrimaryColor;
		this.SecondaryColor = wineWizardStyle.SecondaryColor;
		this.FontColorPrimary = wineWizardStyle.FontColorPrimary;
		this.FontColorSecondary = wineWizardStyle.FontColorSecondary;
		this.AvatarImageUrl = wineWizardStyle.AvatarImageUrl;
		this.AvatarCentered = wineWizardStyle.AvatarCentered;
		this.TextConventions = wineWizardStyle.TextConventions;
		this.FontColorButton = wineWizardStyle.FontColorButton;
		this.BackgroundColorListSelection = wineWizardStyle.BackgroundColorListSelection;
		this.Bottom = wineWizardStyle.Bottom;
		this.Right = wineWizardStyle.Right;
		this.IconBackgroundColor = wineWizardStyle.IconBackgroundColor;
	}

	static createFromApiResponse(response?: IWineWizardStylResponse | null): WineWizardStyle | null {
		if (!response) return null;

		const newWineWizardStyle = new WineWizardStyle({
			Id: response.id,
			FirstColorWave: response.first_color_wave ?? undefined,
			SecondColorWave: response.second_color_wave ?? undefined,
			PrimaryColor: response.primary_color ?? undefined,
			SecondaryColor: response.secondary_color ?? undefined,
			FontColorPrimary: response.font_color_primary ?? undefined,
			FontColorSecondary: response.font_color_secondary ?? undefined,
			FontColorButton: response.font_color_button ?? undefined,
			BackgroundColorListSelection: response.background_color_list_selection ?? undefined,
			AvatarImageUrl: response.avatar_image ?? undefined,
			AvatarCentered: response.avatar_centered ?? undefined,
			TextConventions: response.text_conventions ?? undefined,
			Bottom: response.bottom ?? undefined,
			Right: response.right ?? undefined,
			IconBackgroundColor: response.icon_background_color ?? undefined
		});

		return newWineWizardStyle;
	}


}
