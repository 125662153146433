
import { defineComponent } from "vue";

export default defineComponent({
	name: "PaperPlane",
	props: {
		color: {
			type: String,
			default: "#B1C1CF",
		},
		hoverColor: {
			type: String,
			default: "#1D74F5",
		},
	},
	data() {
		return {
			currentColor: this.color,
		};
	},
});
