import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e2337a70"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    width: "29",
    height: "29",
    viewBox: "0 0 29 29",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    onMouseover: _cache[0] || (_cache[0] = ($event: any) => (_ctx.currentColor = _ctx.hoverColor)),
    onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.currentColor = _ctx.color))
  }, [
    _createElementVNode("path", {
      d: "M27.7399 1.07904C28.2923 1.46185 28.5821 2.12357 28.4782 2.78529L24.9782 25.5353C24.8962 26.0658 24.5735 26.5306 24.1032 26.7931C23.6329 27.0556 23.0696 27.0884 22.572 26.8806L16.0313 24.1626L12.2852 28.215C11.7985 28.7454 11.0329 28.9204 10.3602 28.6579C9.68758 28.3954 9.25008 27.7447 9.25008 27.0228V22.4509C9.25008 22.2322 9.33211 22.0244 9.47977 21.8658L18.6454 11.8634C18.9626 11.5189 18.9516 10.9884 18.6235 10.6603C18.2954 10.3322 17.7649 10.3103 17.4204 10.622L6.29696 20.504L1.46805 18.0869C0.888363 17.797 0.516488 17.2173 0.500081 16.572C0.483675 15.9267 0.822738 15.3251 1.38055 15.0025L25.8805 1.00248C26.4657 0.668886 27.1876 0.701698 27.7399 1.07904Z",
      fill: _ctx.currentColor
    }, null, 8, _hoisted_1)
  ], 32))
}