export default function ensureClosedSentence(text: string): string {
    // Trim any trailing white spaces
    text = text.trim();

    // Check if the string ends with a punctuation mark that indicates the end of a sentence
    const punctuationMarks = [".", "!", "?"];
    if (punctuationMarks.some((mark) => text.endsWith(mark))) {
        // String already ends with a punctuation mark
        return text;
    } else {
        // Find the last occurrence of a period, exclamation mark, or question mark
        const lastPunctuationIndex = text.search(/[.!?][^.!?]*$/);

        if (lastPunctuationIndex !== -1) {
            // Extract the sentence portion from the last punctuation mark to the end
            const sentence = text.slice(0, lastPunctuationIndex + 1);

            return sentence;
        } else {
            // If no punctuation mark is found, return an empty string
            return "";
        }
    }
}
