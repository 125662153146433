
import { defineComponent, PropType } from "vue";
import Restaurant from "@/entities/domain/Restaurant/Restaurant";
import { Answer } from "@/entities/domain/Quiz/Answer";

export default defineComponent({
	name: "SwipeChoice",
	props: {
		restaurant: {
			type: Object as PropType<Restaurant>,
		},
	},
	emits: ["answerClicked"],
	computed: {
		answers(): Answer[] {
			return this.restaurant?.Quiz.getCurrentQuestion().Answers ?? [];
		},
	},
});
