import { IAnswerResponse } from "@/interfaces/api/responses/IAnswerResponse";

export class Answer {
    Id: number;
    selected?: boolean;
    Text?: string | null;
    TextMultiLang?: {
        [key: string]: string | null | undefined;
    }
    NextQuestion?: number | null;
    NextQuiz?: number | null;
    Picture?: string | null;
    Score?: number | null;
    Value: string | number | null;

    constructor(answer: Answer) {
        this.Id = answer.Id;
        this.Text = answer.Text ?? undefined;
        this.TextMultiLang = answer.TextMultiLang ?? undefined;
        this.NextQuestion = answer.NextQuestion ?? undefined;
        this.NextQuiz = answer.NextQuiz ?? undefined;
        this.Picture = answer.Picture ?? undefined;
        this.Score = answer.Score ?? undefined;
        this.Value = answer.Value;
    }


    static createFromApiResponse(response: IAnswerResponse): Answer {
        const newAnswer = new Answer({
            Id: response.id,
            Text: response.text,
            TextMultiLang: {},
            NextQuestion: response.nextQuestion,
            NextQuiz: response.nextQuiz,
            Picture: response.picture,
            Score: response.score,
            Value: response.value,
        });

        Object.keys(response).forEach(key => {

            if (key.includes("text")) {
                newAnswer[key] = response[key];
            }
            if(key.includes("explanation")) {
                newAnswer[key] = response[key];
            }

        });
    

        return newAnswer;
    }
}