
import { Answer } from "@/entities/domain/Quiz/Answer";
import { defineComponent, PropType } from "vue";

export default defineComponent({
	name: "MultiplePictureChoiceButton",
	props: {
		answer: {
			type: Object as PropType<Answer>,
			default: null,
		},
		showCaption: {
			type: Boolean,
		},
		small: {
			type: Boolean,
		},
		selectedAnswer: {
			type: Boolean,
		},
		versusMode: {
			type: Boolean,
			default: false,
		},
		textColor: {
			type: String
		}
	},
	methods: {
		getImg(img: string | null | undefined) {
			if (!img) return null;

			if (img[0] === "/") {
				return require("@/assets" + img);
			} else if (img[0] === "@") {
				return require("@/assets" + img.replace("@/assets", ""));
			} else {
				return img;
			}
		},
	},
});
