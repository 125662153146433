
import Restaurant from "@/entities/domain/Restaurant/Restaurant";
import { defineComponent, PropType } from "vue";

export default defineComponent({
	name: "RestaurantSelectionPage",
	props: {
		restaurant: {
			type: Object as PropType<Restaurant>,
			default: {} as Restaurant,
		},
		snippetRestaurantCode: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			standardRestaurantCode: window.document.getElementById("willi-wein")?.getAttribute("restaurant-code")
		};
	},
	async mounted() {
		// Make Info Willi visible when new Session is loaded
		sessionStorage.removeItem("infoWilliSlider");

		// If RestaurantCode is provided in url, use that
		const urlHashData = window.location.hash.slice(2); // Get everything after #/ in url
		if (
			urlHashData &&
			!urlHashData.includes("/") &&
			!this.$router.options.routes.find((route) =>
				urlHashData.includes(route.path.substring(1, route.path.indexOf("/:") - 1))
			)
		) {
			const oldRestaurantCode = localStorage.getItem("restaurantCode");
			await this.restaurant.loadRestaurantAsync(urlHashData.toUpperCase(), true);
			const loadingResult = await this.restaurant.loadRestaurantAsync(urlHashData.toUpperCase(), true);
			if (!loadingResult.Success) {
				await this.restaurant.loadRestaurantAsync(oldRestaurantCode ?? this.snippetRestaurantCode, true)
			}

		}
		this.standardRestaurantCode = window.document.getElementById("willi-wein")?.getAttribute("restaurant-code")
	},
});
