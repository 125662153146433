
import { defineComponent } from "vue";
import QrcodeVue from "qrcode.vue";

export default defineComponent({
	name: "QRCodeComponent",
	emits: ["restartQuiz"],
	data() {
		return {
			size: 300,
		};
	},
	components: {
		QrcodeVue,
	},
	props: {
		qrCodeUrl: {
			type: String,
			default: "",
		},
	},
	mounted() {
		// reload / return to internorga quiz window after two minutes to restart quiz
		setTimeout(() => {
			this.goToPage();
		}, 120000);
	},
	methods: {
		goToPage() {
			if (window.top)
				window.top.location.href = "https://www.tailorwine.de/internorga-quiz/#/";
			parent.postMessage("reload", "*");
			this.$emit("restartQuiz"); // In Case Page Loading is not possible (IFrame)
		},
	},
});
