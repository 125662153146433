
import { defineComponent, PropType } from "vue";
import Restaurant from "@/entities/domain/Restaurant/Restaurant";
import { Answer } from "@/entities/domain/Quiz/Answer";

export default defineComponent({
	name: "SingleChipSelection",
	props: {
		restaurant: {
			type: Object as PropType<Restaurant>,
		},
	},
	emits: ["setAnswer"],
	data() {
		return {
			selectedChipAnswerId: -1,
		};
	},
	methods: {
		answerClicked(answer: Answer) {
			this.selectedChipAnswerId = answer.Id;
			this.$emit("setAnswer", { answerId: answer.Id, answerValue: Number(answer.Score) });
		},
	},
});
