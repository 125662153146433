
import { defineComponent } from "vue";

export default defineComponent({
	props: {
		startColor: {
			type: String,
			default: "#621940",
		},
		stopColor: {
			type: String,
			default: "#F77A7A",
		},
	},
	computed:{
		isBigScreen(){
			return window.innerWidth > 1500;
		}
	}
});
