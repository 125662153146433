
import Arrow from "@/components/svg/Arrow.vue";

import { Wine } from "@/entities/domain/Match/Wine";
import Restaurant from "@/entities/domain/Restaurant/Restaurant";
import { StylingVariant, WineButtonAction, WineCardVersion } from "@/entities/enums/QuizConfigEnums";
import { defineComponent, PropType } from "vue";
import WineCard from "../Result/WineCard.vue";
import WineCardV2 from "@/components/BaseComponents/WineCardV2.vue";
import QuizService from "@/services/QuizService";
import { Chatbot } from "@/entities/domain/Chatbot/Chatbot";
import { Message } from "@/entities/domain/Chatbot/Message";
import { MessageSender } from "@/entities/enums/ChatbotEnums";
import { WineFeedbackCause } from "@/entities/enums/WineFeedbackCause";
import { WineFilter, Operator } from "@/entities/domain/Match/WineListFilter";
import { Answer } from "../../../entities/domain/Quiz/Answer"
import QuerySelectorAll from "@/utils/QuerySelectorAll";
import QrcodeVue from 'qrcode.vue';

export default defineComponent({
	name: "SingleMessage",
	props: {
		message: {
			type: Object as PropType<Message>,
			required: true,
		},
		wineRecommendation: {
			type: Object as PropType<Wine[]>,
		},
		restaurant: {
			type: Object as PropType<Restaurant>,
			required: true,
		},
		chatbot: {
			type: Object as PropType<Chatbot>,
			required: true,
		},
		avatar: {
			type: String,
			required: true,
		},
		isExpertQuiz: {
			type: Boolean,
			required: true,
		}
	},
	data() {
		return {
			localChatbot: { ...this.chatbot } as Chatbot,
			firstV2CardFlipped: true,
		}
	},
	created() {
		this.localChatbot = { ...this.chatbot } as Chatbot;
	},
	mounted() {

		// this.$nextTick(() => {
		// 	let maxCardHeight = 0;
		// 	const cards = QuerySelectorAll('.winecard .card');
		// 	const innerCards = QuerySelectorAll('.winecard .card .cardInner');
		// 	innerCards?.forEach((card: any) => {
		// 		const cardHeight = card.offsetHeight;
		// 		if (cardHeight > maxCardHeight) maxCardHeight = cardHeight;
		// 	});

		// 	maxCardHeight += 20;

		// 	if(maxCardHeight > 450)
		// 		maxCardHeight = 450;

		// 	cards?.forEach((card: any) => {
		// 		card.style.minHeight = maxCardHeight + 'px';
		// 	});
		// });

		setTimeout(() => {
			this.firstV2CardFlipped = false;
		}, 1000);
	},
	computed: {
		isMessageSenderWilli() {
			return this.message.Sender === MessageSender.WILLI;
		},
		stylingVariant() {
			return StylingVariant.CHATBOT;
		},
		filteredWineResults() {
			if (!this.localChatbot.WineFilter)
				return this.wineRecommendation;

			return this.localChatbot.WineFilter.FilterWineList(this.wineRecommendation ?? [], this.restaurant.RestaurantQuizConfig.AdvancedWineResultFiltering);
		},
		chatbotTexts() {
			return this.restaurant.FrontendDesign?.WineWizard?.TextConventions ?? undefined;
		}
	},
	components: { WineCard, Arrow, WineCardV2, QrcodeVue },
	methods: {
		toggleAnswerSelection(answer: Answer) {
			answer.selected = !answer.selected;
		},
		sendMultipleAnswers() {
			const selectedAnswers: any = this.$props.message.AnswerSelection?.filter(answer => answer.selected);
			if (selectedAnswers && selectedAnswers.length > 0) {
				// Set the 'Text' property of each selected answer to the comma-separated string
				const selectedTexts: string[] = selectedAnswers.map(answer => answer.Text);

				// Create a single object with Text as comma-separated string
				const finalAnswer: Answer = {
					...selectedAnswers[0],  // Use properties from the first selected answer
					Text: selectedTexts.join(', '),  // Set Text as comma-separated string
					text: selectedTexts.join(', '),  // Set text as comma-separated string
				};
				// Call the chatbot method with the modified answers
				this.$props.chatbot.SetAnswerForQuiz(
					this.$props.restaurant,
					finalAnswer,
					// this.$props.restaurant.RestaurantQuizConfig.ExtraChatBotQuiz,
					(this.$props.isExpertQuiz === true ? this.$props.restaurant.RestaurantQuizConfig.ExtraChatBotQuiz : this.$props.restaurant.RestaurantQuizConfig.ExpertQuiz),
					this.$props.isExpertQuiz
				);

				// Reset selected state for answers
				selectedAnswers.forEach(answer => {
					answer.selected = false;
				});
			} else {
				// Provide a message or take any other action if no answers are selected
				console.warn('Please select at least one answer before clicking "Select Multiple".');
			}
		},
		wineClicked(wine: Wine) {
			if (this.restaurant.RestaurantQuizConfig.EndscreenWineButtonAction === WineButtonAction.SAVEMODE || this.restaurant.RestaurantQuizConfig.EndscreenWineButtonAction === WineButtonAction.SHOWQRCODE 
			|| this.restaurant.RestaurantQuizConfig.EndscreenWineButtonAction === WineButtonAction.OPENNAVIGATIONMAP
			) {
				this.$emit("wineClicked", wine);
			}
			else {
				window.open(wine.Handle, "_blank")?.focus();
			}
			QuizService.sendEvent(JSON.stringify({ source: "chatbot", wine: wine.Id }), "LinkOpened")
		},
		scrollWineCards(direction: number) {
			const winecardContainer = this.$el.querySelector('.winecard') as HTMLElement;
			const winecard = winecardContainer.querySelector('.winecard > :first-child') as HTMLElement;
			const winecardWidth = winecard?.offsetWidth ?? 0;
			const winecardMargin = parseFloat(getComputedStyle(winecard).marginRight) + parseFloat(getComputedStyle(winecard).marginLeft);
			const scrollAmount = direction * (winecardWidth + winecardMargin);
			const containerWidth = winecardContainer.offsetWidth;
			const maxScrollPosition = winecardContainer.scrollWidth - containerWidth;
			let newScrollPosition = winecardContainer.scrollLeft + scrollAmount;

			if (newScrollPosition < 0) {
				newScrollPosition = 0;
			} else if (newScrollPosition > maxScrollPosition) {
				newScrollPosition = maxScrollPosition;
			} else {
				const cardIndex = Math.floor(newScrollPosition / (winecardWidth + winecardMargin));
				newScrollPosition = cardIndex * (winecardWidth + winecardMargin);
			}

			winecardContainer.scrollTo({
				left: newScrollPosition,
				behavior: 'smooth',
			});
		},
		wineFeedbackCauseSelected(feedbackData: { Wine: Wine, Cause: WineFeedbackCause }) {
			// Save Feedback  as WineFilters
			this.localChatbot.WineFilter!.WineFilterOptions = [
				...this.localChatbot.WineFilter!.WineFilterOptions,
				new WineFilter({
					PropertyName: "Id",
					Operator: Operator.NotEqual,
					ValueToCompare: feedbackData.Wine.Id
				} as WineFilter)
			]

			this.localChatbot.WineFilter!.WineFilterOptions = [
				...this.localChatbot.WineFilter!.WineFilterOptions,
				new WineFilter({
					PropertyName: feedbackData.Cause,
					Operator: Operator.NotEqual,
					ValueToCompare: feedbackData.Wine[feedbackData.Cause]
				} as WineFilter)
			]
		},
		singleActionClicked(answer: Answer) {
			if( !answer.NextQuestion && answer.NextQuiz !== this.restaurant.RestaurantQuizConfig.ExtraChatBotQuiz && answer.NextQuiz !== this.restaurant.RestaurantQuizConfig.ExpertQuiz)
				this.$emit("hardStartQuiz");
			else{
				const currentQuiz = this.isExpertQuiz ? this.restaurant.RestaurantQuizConfig.ExpertQuiz : this.restaurant.RestaurantQuizConfig.ExtraChatBotQuiz;
				
				this.chatbot.SetAnswerForQuiz(this.restaurant, answer, currentQuiz, this.isExpertQuiz); 
			}
		},
		openCouponUrl() {
			window.open(this.restaurant.RestaurantQuizConfig.CouponUrl, "_blank")?.focus();
		}
	},
});
