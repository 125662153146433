import { IProductLocationResponse } from "@/interfaces/api/responses/Matching/IProductLocationResponse";

export class ProductLocation{
    ShelfNumber: string;
    NavigationMapLink: string;

    constructor(productLocation: ProductLocation) {
        this.ShelfNumber = productLocation.ShelfNumber;
        this.NavigationMapLink = productLocation.NavigationMapLink;
    }

    static createFromApiResponse(apiResponse: IProductLocationResponse): ProductLocation {
        return new ProductLocation({
            ShelfNumber: apiResponse.shelf_number,
            NavigationMapLink: apiResponse.navigation_map_link
        });
    }
}