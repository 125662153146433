
import { defineComponent } from "vue";

export default defineComponent({
	name: "QuizRestart",
	props: {
		restaurant: {
			type: Object,
			required: true,
		},
	},
	mounted() {
		setTimeout(() => {
			this.$emit("restartQuiz");
		}, 4000);
	},
});
