
import { defineComponent } from "vue";

export default defineComponent({
	props: {
		baseColor: {
			type: String,
			default: "#621940",
		},
	},
});
