import { IRestaurantResponse } from "@/interfaces/api/responses/IRestaurantResponse";
import Quiz from "@/entities/domain/Quiz/Quiz";
import { FrontendDesign } from "./FrontendDesign";
import { RestaurantQuizConfig } from "./RestaurantQuizConfig";
import QuizService from "@/services/QuizService";
import { Match } from "../Match/Match";
import { Question } from "../Quiz/Question";
import { Translations } from "../Translations";
import {FirstLevelSupport} from "../FirstLevelSupport/FirstLevelSupport";

import Router from "@/router";
import { Languages, WineButtonAction } from "@/entities/enums/QuizConfigEnums";
import { QuestionType } from "@/entities/enums/QuizEnums";
import { Answer } from "../Quiz/Answer";
import { QuestionFrontendSetting } from "../Quiz/QuestionFrontendSetting";
import { AnswerForMatching } from "@/entities/api/AnswerForMatching";
import { Wine } from "../Match/Wine";

import AddToStyling from "@/utils/AddToStyling";
import axios from "axios";
import { OperationResult } from "../OperationResult";
import DisplayedTexts from "../DisplayedTexts";
import DispatchEvent from "@/utils/DispatchEvent";

export default class Restaurant {
	private _homepageMode = false;
	private _selectedLanguage = Languages.DE;
	private _languageWasSelected = false;
	private _baseComponentTranslations = {} as Translations;
	private _isQuizClosed = true;
	private _isRestaurantLoaded = false;
	private _isSavedSessionLoaded = false;
	private _autoRedirect = false;
	private _isEmailQuestionProvided = false;

	private _quiz = {} as Quiz;

	RestaurantCode?: string;
	FrontendDesign?: FrontendDesign;
	RestaurantQuizConfig: RestaurantQuizConfig;
	FloorPlan?: string;
	Match?: Match;

	Token?: string;

	AutoRedirect: boolean;

	DisplayedTexts: DisplayedTexts;

	FirstLevelSupport?: FirstLevelSupport;

	constructor(restaurant?: Restaurant) {
		this.RestaurantCode = restaurant?.RestaurantCode;
		this.FrontendDesign = restaurant?.FrontendDesign ?? ({} as FrontendDesign);
		this.RestaurantQuizConfig =
			restaurant?.RestaurantQuizConfig ?? ({} as RestaurantQuizConfig);
		this.FloorPlan = restaurant?.FloorPlan;
		this.Match = restaurant?.Match;
		this.Token = restaurant?.Token;
		this.AutoRedirect = restaurant?.AutoRedirect ?? false;
		this.DisplayedTexts = new DisplayedTexts(this);
		this.FirstLevelSupport = restaurant?.FirstLevelSupport;
	}

	static createFromApiResponse(
		response: IRestaurantResponse,
		restaurantCode: string
	): Restaurant {
		// TODO: add missing api mapper

		const newRestaurant = {
			RestaurantCode: restaurantCode,
			Quiz: Quiz.createFromApiResponse(response.quiz),
			FrontendDesign:
				FrontendDesign.createFromApiResponse(response.frontend_design) ?? undefined,
			RestaurantQuizConfig: RestaurantQuizConfig.createFromApiResponse(response.config ?? {}),
			FloorPlan: response.floor_plan,
			Token: response.token,
			FirstLevelSupport: response.first_level_support ? FirstLevelSupport.createFromApiResponse(response.first_level_support) : undefined,
		} as Restaurant;

		return newRestaurant;
	}

	async loadRestaurantAsync(
		restaurantCode: string,
		hardReset = false,
		quizId: number | undefined = undefined
	): Promise<OperationResult<string>> {
		if (!restaurantCode)
			return new OperationResult<string>({
				Success: false,
				ErrorMessage: "No RestaurantCode provided in Restaurant-Loader",
			});

		if (this.Quiz && this.Quiz?.Questions && !hardReset)
			return new OperationResult<string>({
				Success: false,
				ErrorMessage: "Quizdata already loaded and no hard reset was requested",
			});

		if (hardReset) this.Quiz.QuestionIdHistory = [];

		if (
			["BESCHREIBUNG", "AUSZEICHNUNGEN", "LEBENSMITTELNACHWEIS"].some((keyword) =>
				localStorage
					.getItem("restaurantCode")
					?.toUpperCase()
					.includes(keyword.toUpperCase())
			)
		)
			localStorage.removeItem("restaurantCode");

		this.RestaurantCode = restaurantCode;
		const restaurantCodeToLoadQuizFor: string = restaurantCode;

		window.localStorage.setItem("restaurantCode", restaurantCodeToLoadQuizFor); // Always save last loaded Quiz

		let quizResponse;
		try {
			quizResponse = await QuizService.getQuiz(restaurantCodeToLoadQuizFor, quizId);
		} catch (error) {
			console.error("Quiz could not be loaded");
			console.error({ error });
			return new OperationResult<string>({
				Success: false,
				ErrorMessage: "Error while loading Restaurant from QuizService",
			});
		}

		if (!quizResponse.Success || !quizResponse)
			return new OperationResult<string>({
				Success: false,
				ErrorMessage:
					quizResponse.ErrorMessage ??
					"Error while loading Restaurant from QuizService, no Restaurant was returned",
			});

		const loadedRestaurant = Restaurant.createFromApiResponse(
			quizResponse.Data,
			restaurantCodeToLoadQuizFor
		);

		if (
			// restaurantCodeToLoadQuizFor === "TW-HOME" ||
			loadedRestaurant.RestaurantQuizConfig.TransparentMode
		)
			this._homepageMode = true;
		else this._homepageMode = false;

		this._isRestaurantLoaded = true;

		loadedRestaurant.Quiz = Quiz.filterQuizForLanguage(
			loadedRestaurant.Quiz,
			this._selectedLanguage
		);

		if (quizResponse.Data.translations) {
			this._baseComponentTranslations = quizResponse.Data.translations;
			// this._baseComponentTranslations = Restaurant.filterBasicComponentsForTranslation(
			// 	this._baseComponentTranslations,
			// 	this._selectedLanguage
			// );
		}

		this.Quiz = loadedRestaurant.Quiz;
		this.FrontendDesign = loadedRestaurant.FrontendDesign;
		this.RestaurantQuizConfig = loadedRestaurant.RestaurantQuizConfig;
		this.FloorPlan = loadedRestaurant.FloorPlan;
		this.Token = loadedRestaurant.Token;

		this.FirstLevelSupport = loadedRestaurant.FirstLevelSupport;

		this.loadImagesToCache(); // Preload Images for performance

		AddToStyling(`*{
			--primary-color: ${this.FrontendDesign?.PrimaryColor ?? "#621940"};
			--secondary-color: ${this.FrontendDesign?.SecondaryColor ?? "#f77a7a"};
			--background-color: ${this.FrontendDesign?.BackgroundColor ?? "#ebe8e1"};
			--secondary-color-light: ${this.FrontendDesign?.SecondaryLightColor ?? "#e1e1da"};
			--primary-font-color: ${this.FrontendDesign?.PrimaryFontColor ?? "#333333"};
			--secondary-font-color: ${this.FrontendDesign?.SecondaryFontColor ?? "#f77a7a"};
			--disabled-button-background-color:  ${
				this.FrontendDesign?.DisabledButtonBackgroundColor ?? "#ccc"
			};
			--disabled-button-font-color:  ${this.FrontendDesign?.DisabledButtonFontColor ?? "#fff"};
			--progress-bar-background-color:  ${this.FrontendDesign?.ProgressBarBackgroundColor ?? "#f77a7a"};
			--fancy-button-stop-color:  ${this.FrontendDesign?.FancyButtonWaveEndColor ?? "#f77a7a"};
			--stepless-slider-background-color:  ${
				this.FrontendDesign?.SteplessSliderBackgroundColor ?? "#f77a7a"
			};
			--primary-font-family: ${this.FrontendDesign?.PrimaryFontFamily ?? "Zilla Slab"};
			--secondary-font-family: ${this.FrontendDesign?.SecondaryFontFamily ?? "Open Sans"};
			--primary-font-weight: ${this.FrontendDesign?.PrimaryFontWeight ?? "bold"};
			--secondary-font-weight: ${this.FrontendDesign?.SecondaryFontWeight ?? "400"};
			--slider-thumb: ${
				this.FrontendDesign?.SliderThumbImage ??
				'url("~@/assets/img/tailorwine_slider_button.svg")'
			}";

			--wizard-first-color-wave: ${this.FrontendDesign?.WineWizard?.FirstColorWave ?? "#1C1027"};
			--wizard-second-color-wave: ${this.FrontendDesign?.WineWizard?.SecondColorWave ?? "#90173E"};
			--wizard-primary-color: ${this.FrontendDesign?.WineWizard?.PrimaryColor ?? "#7B163A"};
			--wizard-secondary-color: ${this.FrontendDesign?.WineWizard?.SecondaryColor ?? "#1D74F5"};
			--wizard-font-color-primary: ${this.FrontendDesign?.WineWizard?.FontColorPrimary ?? "#fff"};
			--wizard-font-color-secondary: ${this.FrontendDesign?.WineWizard?.FontColorSecondary ?? "#000"};

			--wizard-background-color-list-selection: ${this.FrontendDesign?.WineWizard?.BackgroundColorListSelection ?? "#fff"};
			--wizard-font-color-button: ${this.FrontendDesign?.WineWizard?.FontColorButton ?? "#fff"};
		}`);

		if(this.RestaurantQuizConfig.Languages?.length === 1 && this.RestaurantQuizConfig.Languages){
			this.setLanguage(this.RestaurantQuizConfig.Languages[0] as Languages);
			this._selectedLanguage = this.RestaurantQuizConfig.Languages[0] as Languages;
		}
		else
			this.DisplayedTexts = new DisplayedTexts(this);

		this.Quiz.QuestionIdHistory.push(this.Quiz.Questions[0].Id); // Start with first Question
		if (
			!this.RestaurantQuizConfig.WilliAsFeature?.RunInFeatureMode &&
			this.RestaurantQuizConfig.Languages
		) {
			this.hideScrollbar();
			this._isQuizClosed = false;
			await Router.push("/languageSelection");
		} else if (!this.RestaurantQuizConfig.WilliAsFeature?.RunInFeatureMode) {
			this.hideScrollbar();
			this._isQuizClosed = false;
			await Router.push("/question/" + this.Quiz.getCurrentQuestion().Id);
		} else {
			this.showScrollbar();
			this._isQuizClosed = true;
			await Router.push("/");
		}

		return new OperationResult<string>({ Success: true, Data: this.RestaurantCode });
	}

	async loadSavedSessionAsync(matchInfo: string | undefined = undefined): Promise<void> {
		const url = new URL(window.location.href);
		let sessionId = url.searchParams.get("id");
		let sessionHash = url.searchParams.get("hash");
		const restaurantCode = url.searchParams.get("restaurantCode");
		const shelfNumberToNavigateTo = url.searchParams.get("shelfNumber"); // If QR-Code of navMap is scanned, open the map
		const showRestartButton = url.searchParams.get("showRestartButton") === "true" ?? undefined;
		const selectedLanguage = url.searchParams.get("language");

		if((!sessionId || !sessionHash) && matchInfo)
		{
			const url = matchInfo;
			const urlObj = new URL(url);
			const searchParams = urlObj.searchParams;

			sessionId = searchParams.get("id");
			sessionHash = searchParams.get("hash");
		}

		const localMatching = localStorage.getItem("match");
		if (localMatching) {
			this.Match = JSON.parse(localMatching);
			this._isQuizClosed = window.location.hash.endsWith("#/result") ? false : true;
		}

		if ((!sessionId || !sessionHash) && !this.Match)
			return;

		if (restaurantCode) {
			this.RestaurantCode = restaurantCode;
			localStorage.setItem("restaurantCode", this.RestaurantCode);
		}

		if (shelfNumberToNavigateTo) {
			this.RestaurantQuizConfig.EndscreenWineButtonAction =
				WineButtonAction.OPENNAVIGATIONMAP;
		}

		if (sessionId && sessionHash) {
			const loadedMatching = await QuizService.getSavedSessionMatch(sessionId, sessionHash);
			this.Match = loadedMatching.match;
			localStorage.setItem("match", JSON.stringify(this.Match))
			localStorage.setItem("sessionId", sessionId);
			localStorage.setItem("sessionHash", sessionHash);

			if(this.RestaurantQuizConfig.MatchingCallbackActive){
				DispatchEvent("matchCallback", {
					tasteType: this.Match.TasteType.Name,
					matchUrl: this.Match.QrUrl + `&restaurantCode=${this.RestaurantCode}&language=${this._selectedLanguage}`
				});
			}

			if (loadedMatching.restaurantCode) {
				this.RestaurantCode = loadedMatching.restaurantCode;
				localStorage.setItem("restaurantCode", this.RestaurantCode);
			}

			this.RestaurantQuizConfig.ShowRestartButton =
				loadedMatching.match?.RestaurantConfig?.ShowRestartButton ?? showRestartButton; // When Info is provided in URL, it should be ranked higher than the server response
		} else this.RestaurantCode = localStorage.getItem("restaurantCode") ?? undefined;

		if (!this.RestaurantCode && localStorage.getItem("restaurantCode"))
			this.RestaurantCode = localStorage.getItem("restaurantCode") ?? undefined;
		if (!this.RestaurantCode)
			return;
		const loadedRestaurant = await QuizService.getQuiz(this.RestaurantCode);

		if (!loadedRestaurant.Success || !loadedRestaurant.Data)
			return;

		const quizOfLoadedMatching = Restaurant.createFromApiResponse(
			loadedRestaurant.Data,
			this.RestaurantCode
		); // TODO: remove redundance
		// this.Quiz = quizOfLoadedMatching.Quiz;
		this.FrontendDesign = quizOfLoadedMatching.FrontendDesign;
		this.RestaurantQuizConfig = quizOfLoadedMatching.RestaurantQuizConfig;
		this.FloorPlan = quizOfLoadedMatching.FloorPlan;
		this.Token = quizOfLoadedMatching.Token;

		this.FirstLevelSupport = quizOfLoadedMatching.FirstLevelSupport;

		this._isRestaurantLoaded = true;

		if (selectedLanguage && Languages[selectedLanguage])
			this.setLanguage(Languages[selectedLanguage]);

		if (loadedRestaurant.Data.translations) {
			this._baseComponentTranslations = loadedRestaurant.Data.translations;
			this._baseComponentTranslations = Restaurant.filterBasicComponentsForTranslation(
				this._baseComponentTranslations,
				this._selectedLanguage
			);
		}

		AddToStyling(`*{
			--primary-color: ${this.FrontendDesign?.PrimaryColor ?? "#621940"};
			--secondary-color: ${this.FrontendDesign?.SecondaryColor ?? "#f77a7a"};
			--background-color: ${this.FrontendDesign?.BackgroundColor ?? "#ebe8e1"};
			--secondary-color-light: ${this.FrontendDesign?.SecondaryLightColor ?? "#e1e1da"};
			--primary-font-color: ${this.FrontendDesign?.PrimaryFontColor ?? "#333333"};
			--secondary-font-color: ${this.FrontendDesign?.SecondaryFontColor ?? "#f77a7a"};
			--disabled-button-background-color:  ${
				this.FrontendDesign?.DisabledButtonBackgroundColor ?? "#ccc"
			};
			--disabled-button-font-color:  ${this.FrontendDesign?.DisabledButtonFontColor ?? "#fff"};
			--progress-bar-background-color:  ${this.FrontendDesign?.ProgressBarBackgroundColor ?? "#f77a7a"};
			--fancy-button-stop-color:  ${this.FrontendDesign?.FancyButtonWaveEndColor ?? "#f77a7a"};
			--stepless-slider-background-color:  ${
				this.FrontendDesign?.SteplessSliderBackgroundColor ?? "#f77a7a"
			};
			--primary-font-family: ${this.FrontendDesign?.PrimaryFontFamily ?? "Zilla Slab"};
			--secondary-font-family: ${this.FrontendDesign?.SecondaryFontFamily ?? "Open Sans"};
			--primary-font-weight: ${this.FrontendDesign?.PrimaryFontWeight ?? "bold"};
			--secondary-font-weight: ${this.FrontendDesign?.SecondaryFontWeight ?? "400"};
			--slider-thumb: ${
				this.FrontendDesign?.SliderThumbImage ??
				'url("~@/assets/img/tailorwine_slider_button.svg")'
			}";

			--wizard-first-color-wave: ${this.FrontendDesign?.WineWizard?.FirstColorWave ?? "#1C1027"};
			--wizard-second-color-wave: ${this.FrontendDesign?.WineWizard?.SecondColorWave ?? "#90173E"};
			--wizard-primary-color: ${this.FrontendDesign?.WineWizard?.PrimaryColor ?? "#7B163A"};
			--wizard-secondary-color: ${this.FrontendDesign?.WineWizard?.SecondaryColor ?? "#1D74F5"};
			--wizard-font-color-primary: ${this.FrontendDesign?.WineWizard?.FontColorPrimary ?? "#fff"};
			--wizard-font-color-secondary: ${this.FrontendDesign?.WineWizard?.FontColorSecondary ?? "#000"};
		}`);

		// TODO: check bottleClickAction
		if ((sessionHash && sessionId) || window.location.hash.endsWith("#/result")) {
			const ResultQuestion: Question = {
				Id: 1,
				Type: QuestionType.ProfileChoice,
				Position: 1,
				Text: "",
				Headline: "",
				FrontendSettings: shelfNumberToNavigateTo
					? [{ shelfNumberToNavigate: shelfNumberToNavigateTo }]
					: ([] as QuestionFrontendSetting[]),
				Answers: [] as Answer[],
			} as Question;

			this.Quiz = new Quiz({
				Name: "Loaded Session",
				Questions: [ResultQuestion] as Question[],
				QuestionIdHistory: [ResultQuestion.Id] as number[],
				SelectedAnswers: [] as AnswerForMatching[],
				CurrentSelectedAnswer: undefined,
			} as Quiz);

			this._isSavedSessionLoaded = true;

			this.RestaurantQuizConfig.QrCodeMode = this.Match?.QrCodeMode;

			this._isRestaurantLoaded = true;

			this._isQuizClosed = false;
			this.hideScrollbar();
			await Router.push("/result");
		} else{
			this.loadRestaurantAsync(this.RestaurantCode, true)
		}
	}

	onAnswerClicked(answer?: { answerId: number; answerValue: string | number } | null): void {
		if (!answer) {
			this.Quiz.CurrentSelectedAnswer = undefined;
			return;
		}

		this.Quiz.CurrentSelectedAnswer = new Answer({
			Id: answer.answerId,
			Value: answer.answerValue,
		});
	}

	async setAnswerAsync(
		selectedAnswer?: { answerId: number; answerValue: string | number } | null
	): Promise<void> {
		const selectedAnswerLocal = selectedAnswer
			? new Answer({ Id: selectedAnswer.answerId, Value: selectedAnswer.answerValue })
			: (this.Quiz.CurrentSelectedAnswer as Answer);
		this.Quiz.CurrentSelectedAnswer = undefined;

		if (!selectedAnswerLocal) return;

		const answer = this.Quiz.getAnswerById(selectedAnswerLocal.Id);

		if (answer.NextQuiz) {
			// this.quizId = null; // TODO: Check if needed
			this.Quiz = {} as Quiz;

			// If Answer has next_quiz, open QuizId
			await this.loadRestaurantAsync(this.RestaurantCode ?? "", true, answer.NextQuiz); // TODO: Check if empty string can be a problem
		}

		let nextQuestion = answer.NextQuestion
			? this.Quiz.getQuestionById(answer.NextQuestion as number)
			: null;

		// If next question just send current answer
		if (answer.NextQuestion) {
			// Dont await async request to do that in background
			QuizService.sendAnswer(
				this.Quiz.getCurrentQuestion().Id,
				selectedAnswerLocal.Value,
				answer,
				this.Quiz.getCurrentQuestion()
			);

			this.Quiz.SelectedAnswers.push(
				new AnswerForMatching({
					AnswerId: this.Quiz.getAnswerById(selectedAnswerLocal.Id).Id,
					AnswerValue: selectedAnswerLocal.Value?.toString(),
					AnswerScore: this.Quiz.getAnswerById(selectedAnswerLocal.Id).Score,
					QuestionId: this.Quiz.getCurrentQuestion().Id,
				})
			);
			this.Quiz.QuestionIdHistory.push(answer.NextQuestion);

			if (this.Quiz.getQuestionById(answer.NextQuestion).Type === QuestionType.ProfileChoice)
				await Router.push("/result");
			else await Router.push("/question/" + answer.NextQuestion);

			// Autoredirect? aka Shopify: skip EmailChoice
			if (nextQuestion?.Type === QuestionType.EmailChoice && this._autoRedirect) {
				this._isEmailQuestionProvided = true;
				const nextQuestionId = nextQuestion.Answers[0].NextQuestion as number;
				this.Quiz.QuestionIdHistory.push(nextQuestionId);
				nextQuestion = answer.NextQuestion
					? this.Quiz.getQuestionById(nextQuestionId)
					: null;
			}

			if (window.innerWidth <= 850 || window.innerWidth >= 2000) {
				process.env.NODE_ENV == "development"
					? window.document.getElementsByClassName("tt_wrapper")[0].scrollIntoView({
							behavior: "smooth",
							block: "start",
							inline: "nearest",
					})
					: window.document
							.getElementById("willi-wein")
							?.shadowRoot?.querySelector(".tt_wrapper")
							?.scrollIntoView({
								behavior: "smooth",
								block: "start",
								inline: "nearest",
							});
			}
		} else if (this.Quiz.getCurrentQuestion().Type === QuestionType.ProfileChoice) {
			QuizService.sendAnswer(
				this.Quiz.getCurrentQuestion().Id,
				selectedAnswerLocal.Value,
				selectedAnswerLocal,
				this.Quiz.getCurrentQuestion()
			);

			// Send Matchingdata again with email data from profileChoice
			this.Quiz.SelectedAnswers.push(
				new AnswerForMatching({
					AnswerId: this.Quiz.getAnswerById(selectedAnswerLocal.Id).Id,
					AnswerValue:
						this.Quiz.getAnswerById(selectedAnswerLocal.Id).Value?.toString() ??
						selectedAnswerLocal.Value?.toString(),
					AnswerScore: this.Quiz.getAnswerById(selectedAnswerLocal.Id).Score,
					QuestionId: this.Quiz.getCurrentQuestion().Id,
				})
			);

			await QuizService.getMatch(this.Quiz.SelectedAnswers, this._selectedLanguage);
		}

		if (
			nextQuestion &&
			(nextQuestion.Type === QuestionType.ResultChoice ||
				nextQuestion.Type === QuestionType.ProfileChoice)
		) {
			this.getQuizMatching();
		}
	}

	async getQuizMatching() {
		let gtins;
		if (
			this.RestaurantQuizConfig.BTGMode &&
			localStorage.getItem("deviceId") &&
			localStorage.getItem("companyId")
		) {
			// Old Version to get BTG GTINS

			const response = await axios.get(
				`https://weinkiosk.bytheglass.eu/api/companies/${localStorage.getItem(
					"companyId"
				)}/kiosks/${localStorage.getItem("deviceId")}`
			);

			gtins = response.data.taps.map((el: any) => el.wine.ean);
		}

		if (
			this.RestaurantQuizConfig.BTGMode &&
			localStorage.getItem("BTG_GTINS")
		) {
			// Get BTG GTINS as JSON Array
			gtins = localStorage.getItem("BTG_GTINS")?.split(",");
		}

		const matchingResponse = await QuizService.getMatch(
			this.Quiz.SelectedAnswers,
			this.selectedLanguage,
			gtins ?? undefined
		);
		// Send Answers to API and get Matching

		matchingResponse.RestaurantConfig = this.RestaurantQuizConfig;

		this.Match = matchingResponse;

		// Save Matchinresponse in LocalStorage to not forget it
		localStorage.setItem("match", JSON.stringify(this.Match));
		localStorage.setItem("sessionId", this.Match.SessionId);
		localStorage.setItem("sessionHash", this.Match.SessionHash);

		if(this.RestaurantQuizConfig.MatchingCallbackActive){
			DispatchEvent("matchCallback", {
				tasteType: this.Match.TasteType.Name,
				matchUrl: this.Match.QrUrl + `&restaurantCode=${this.RestaurantCode}&language=${this._selectedLanguage}`
			});
		}

		if(this.RestaurantQuizConfig.SetMatchToUrl)
		{
			const smalifiedMatch = {
				TasteType: this.Match.TasteType,
				Wines: this.Match.Wines.slice(0,5).map(el => {

					const wineToUse = {
						Id: el.Id,
						Name: el.Name,
						Grape: el.Grape,
						GTIN: el.GTIN,
						Country: el.Country,
						Region: el.Region,
						Color: el.Color,
						TasteMatch: el.TasteMatch,
						Handle: el.Handle,
						PictureFront: el.PictureFront,

					}

					return {};
				}),
				Grapes: this.Match.Grapes,
				Aromas: this.Match.Aromas,
			}

			const url = window.location.href;
			const newUrl = url + "?match=" + JSON.stringify(smalifiedMatch);
			window.history.pushState({path: newUrl}, '', newUrl);
		}

		if (window["__IGNDL"]) {
			const IGetNowRecommend = {};

			matchingResponse.Wines.forEach((el: Wine) => {
				if (el.IgnCategory && IGetNowRecommend[el.IgnCategory.toString()]) {
					IGetNowRecommend[el.IgnCategory.toString()].push(el.IgnId);
				} else if (el.IgnCategory) {
					IGetNowRecommend[el.IgnCategory.toString()] = [el.IgnId];
				}
			});

			// When EventArray on Window from IGetNow push Matching as Event
			if (window["__IGNDL"] && matchingResponse.Wines.some((el) => el.IgnId)) {
				window["__IGNDL"].push({
					eventType: "setRecommendations",
					data: {
						categories: IGetNowRecommend,
					},
				});
			}
		} else if (this._autoRedirect) {
			const qrUrl = new URL(matchingResponse.QrUrl);
			const currentUrl = window.location.href.split("#/")[0].replace(/\/$/, "");
			window.location.href = currentUrl + "/" + qrUrl.searchParams.get("id");
		}
	}

	static filterBasicComponentsForTranslation(
		translations: Translations,
		selectedLanguage: string
	): Translations {
		const translationsToReturn = { ...translations };

		for (const [key, value] of Object.entries(translations)) {
			translationsToReturn[key].text = value[selectedLanguage];
		}

		return translationsToReturn;
	}

	getLanguageQuestion(): Question {
		if (!this.RestaurantQuizConfig.Languages) return {} as Question;

		const languageAnswers = this.RestaurantQuizConfig.Languages;

		const languageQuestion = new Question({
			Id: 1,
			Type: QuestionType.SinglePictureChoice,
			Position: 0,
			Text: "Sprache auswählen / Select your language",
			Headline: null,
			Answers: languageAnswers.map((language, index) => {
				return {
					Id: index,
					Text: language.toUpperCase(),
					Score: index,
					Picture: "@/assets/img/" + language + ".svg",

					NextQuestion: 0,
					NextQuiz: 0,
					Selected: false,
					Value: language,
				} as Answer;
			}),
			Explanation: null,
			ExplanationEn: null,
			FrontendSettings: [] as QuestionFrontendSetting[],
			Picture: null,
		});

		return languageQuestion;
	}

	setLanguage(selectedLanguage: Languages): void {
		this._selectedLanguage = selectedLanguage;
		this.Quiz = QuizService.filterQuizForLanguage(this.Quiz, this._selectedLanguage);

		// Filter BaseComponentTranslations for selected Language
		for (const [key, value] of Object.entries(this._baseComponentTranslations)) {
			this._baseComponentTranslations[key].text = value[this._selectedLanguage];
		}

		this._languageWasSelected = true;

		this.DisplayedTexts = new DisplayedTexts(this);
	}

	isWineAvailable(wine: Wine): boolean {
		if (this.isIGetNowModeActive) return !!wine.IgnId;
		else if (
			this.RestaurantQuizConfig.EndscreenWineButtonAction ===
				WineButtonAction.OPENNAVIGATIONMAP ||
			this.RestaurantQuizConfig.EndscreenWineButtonAction === WineButtonAction.SHOWQRCODE ||
			this.RestaurantQuizConfig.EndscreenWineButtonAction === WineButtonAction.STARTTASTING ||
			this.RestaurantQuizConfig.EndscreenWineButtonAction === WineButtonAction.EXPLOREMODE
		) {
			return true;
		} else if (
			this.RestaurantQuizConfig.EndscreenWineButtonAction === WineButtonAction.SAVEMODE
		) {
			return true;
		} else return !!wine.Handle;
	}

	async startQuiz(hardReset?: boolean) {
		if (hardReset) localStorage.removeItem("match");

		await this.loadRestaurantAsync(this.RestaurantCode!, hardReset);

		this._isQuizClosed = false;

		this.hideScrollbar();

		if (this.Match && !hardReset) this.openResult();
		else await Router.push("/question/" + this.Quiz.getCurrentQuestion().Id);
	}

	async openResult() {
		this._isQuizClosed = false;
		this.hideScrollbar();

		// Set resultquestion as current question
		if (this.Quiz.Questions.find((q) => q.Type === QuestionType.ProfileChoice))
			this.Quiz.QuestionIdHistory.push(
				this.Quiz.Questions.find((q) => q.Type === QuestionType.ProfileChoice)!.Id
			);
		else {
			this.Quiz.Questions.push({
				Type: QuestionType.ProfileChoice,
				Id: this.Quiz.Questions.length + 1 ?? 1,
			} as Question);
			this.Quiz.QuestionIdHistory.push(this.Quiz.Questions.length + 1 ?? 1);
		}

		await Router.push("/result");
	}

	hideScrollbar() {
		if (this.RestaurantQuizConfig.WilliAsFeature?.RunInFeatureMode)
			window.document.querySelector("html")!.style.cssText = `
			/* Hide scrollbar for Webkit (Safari, Chrome) */
			-webkit-overflow-scrolling: touch; /* Enable momentum scrolling */
			-webkit-appearance: none; /* Remove default appearance */
			/* Hide scrollbar for Firefox */
			overflow: -moz-scrollbars-none;
			/* Hide scrollbar for Edge and Internet Explorer */
			overflow-y: -ms-autohiding-scrollbar;
			/* Hide scrollbar for all other browsers */
			overflow: hidden;
		`;
	}

	showScrollbar() {
		if (this.RestaurantQuizConfig.WilliAsFeature?.RunInFeatureMode) {
			const html = document.querySelector("html")!;
			html.style.setProperty("-webkit-overflow-scrolling", "");
			html.style.webkitAppearance = "";
			html.style.overflow = "";
			html.style.overflowY = "";
		}
	}

	closeQuiz() {
		this._isQuizClosed = true;

		this.showScrollbar();

		Router.push("/");
	}

	async restartQuiz() {
		// TODO: Fix to reload quiz also in Chatbotmode
		localStorage.removeItem("match");
		const runInFeature = this.RestaurantQuizConfig.WilliAsFeature?.RunInFeatureMode;
		const homepageMode = this.homepageMode;
		const transparentMode = this.RestaurantQuizConfig.TransparentMode;

		this.Match = undefined;
		this.Quiz.SelectedAnswers = [];
		this.Quiz.QuestionIdHistory = [];
		this.Quiz.QuestionIdHistory.push(this.Quiz.Questions[0].Id);
		await this.loadRestaurantAsync(this.RestaurantCode!, true);

		this.isQuizClosed = false;

		if (this.RestaurantQuizConfig.WilliAsFeature)
			this.RestaurantQuizConfig.WilliAsFeature.RunInFeatureMode = runInFeature;

		this.homepageMode = homepageMode;
		this.RestaurantQuizConfig.TransparentMode = transparentMode;

		await Router.push("/question/" + this.Quiz.Questions[0].Id);

		// if (this.RestaurantQuizConfig.WilliAsFeature?.RunInFeatureMode) {
		// 	this.showScrollbar();
		// 	this._isQuizClosed = true;
		// }

		// 	window.location.href =
		// 	location.protocol + "//" + location.host + "/#/" + this.RestaurantCode;
		// 	window.location.reload();
	}

	loadImagesToCache() {
		// preload images when assetPath is set
		const AnswerImages = this.Quiz.Questions.map(({ Answers }) => Answers);

		const tempArr: Answer[] = [];
		tempArr.concat
			.apply([], AnswerImages)
			.filter(({ Picture }) => Picture)
			.map(({ Picture }) => Picture)
			.map((Picture) => {
				const image = new Image();
				image.src = Picture ?? "";
			});
	}

	// Old BTG Version

	async getBtgGtins(){
		let gtins;
		if (
			this.RestaurantQuizConfig.BTGMode &&
			localStorage.getItem("deviceId") &&
			localStorage.getItem("companyId")
		) {
			const response = await axios.get(
				`https://weinkiosk.bytheglass.eu/api/companies/${localStorage.getItem(
					"companyId"
				)}/kiosks/${localStorage.getItem("deviceId")}`
			);

			gtins = response.data.taps.map((el: any) => el.wine.ean);
		}

		if(this.RestaurantQuizConfig.BTGMode && localStorage.getItem("BTG_GTINS"))
		{
			// Get BTG GTINS as JSON Array
			gtins = localStorage.getItem("BTG_GTINS")?.split(",");
		}

		return gtins;
	}

	// Getter and setters for private properties

	// When Quiz get's setted, check for translation
	set Quiz(quiz: Quiz) {
		if(!this._languageWasSelected)
			this._selectedLanguage = localStorage.getItem("language") as Languages;

		if(quiz && quiz.Questions && quiz.Questions.length > 0)
			this._quiz = Quiz.filterQuizForLanguage(quiz, this._selectedLanguage);
	}

	get Quiz(): Quiz {
		return this._quiz;
	}

	get isRestaurantLoaded(): boolean {
		return this._isRestaurantLoaded;
	}

	get homepageMode(): boolean {
		return this._homepageMode;
	}

	set homepageMode(val) {
		this._homepageMode = val;
	}

	get baseComponentTranslations(): Translations {
		return this._baseComponentTranslations;
	}

	get isSavedSessionLoaded(): boolean {
		return this._isSavedSessionLoaded;
	}

	get selectedLanguage(): Languages {
		return this._selectedLanguage;
	}

	get languageWasSelected(): boolean {
		return this._languageWasSelected;
	}

	get isEmailQuestionProvided(): boolean {
		if (this._isEmailQuestionProvided) return true;

		if (this._isSavedSessionLoaded) {
			// if Session is loaded and E-Mail of user is in DB
			return this.Match?.ProvidedContact ?? false;
		} else {
			return this.Quiz.Questions
				? this.Quiz.Questions.some(
						(el) =>
							el.Type === QuestionType.EmailChoice &&
							this.Quiz.QuestionIdHistory.indexOf(el.Id) !== -1
				)
				: false;
		}
	}

	emailFormSend(isProvided: boolean) {
		this._isEmailQuestionProvided = isProvided;
	}

	get isIGetNowModeActive(): boolean {
		return !!window["__IGNDL"];
	}

	get isQuizClosed(): boolean {
		if (
			this.RestaurantQuizConfig.UrlToBlockFeatureModeOn &&
			window.location.href.includes(this.RestaurantQuizConfig.UrlToBlockFeatureModeOn)
		)
			return false;

		return this._isQuizClosed;
	}

	set isQuizClosed(val: boolean) {
		this._isQuizClosed = val;
	}
}
