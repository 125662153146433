import { IGrapeResponse } from "@/interfaces/api/responses/Matching/IGrapeResponse";

export class GrapeType{
    Red: string[];
    White: string[];

    constructor(grapeType: GrapeType) {
        this.Red = grapeType.Red;
        this.White = grapeType.White;
    }

    static createFromApiResponse(response: IGrapeResponse): GrapeType{
        const newGrapeType = {
            Red: response.red,
            White: response.white,
        } as GrapeType;

        return newGrapeType;
    }
}