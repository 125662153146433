
import { defineComponent } from "vue";

export default defineComponent({
	name: "StarSvg",
	props: {
		transparentMode: {
			type: Boolean,
			default: false,
		},
		fill: {
			type: String,
			default: null as string | null,
		},
	},
});
