import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "13",
  height: "17",
  viewBox: "0 0 13 17",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_2 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      d: "M6.49967 0.583313C3.43592 0.583313 0.958008 3.06123 0.958008 6.12498C0.958008 10.2812 6.49967 16.4166 6.49967 16.4166C6.49967 16.4166 12.0413 10.2812 12.0413 6.12498C12.0413 3.06123 9.56342 0.583313 6.49967 0.583313ZM6.49967 8.10415C5.40717 8.10415 4.52051 7.21748 4.52051 6.12498C4.52051 5.03248 5.40717 4.14581 6.49967 4.14581C7.59217 4.14581 8.47884 5.03248 8.47884 6.12498C8.47884 7.21748 7.59217 8.10415 6.49967 8.10415Z",
      fill: _ctx.color
    }, null, 8, _hoisted_2)
  ]))
}