import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StartChoice = _resolveComponent("StartChoice")!

  return (_ctx.restaurant?.Quiz.getCurrentQuestion().Type === 'StartChoice')
    ? (_openBlock(), _createBlock(_component_StartChoice, {
        key: 0,
        restaurant: _ctx.restaurant,
        class: "tt_wrapper",
        onSetAnswer: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('setAnswer', $event)))
      }, null, 8, ["restaurant"]))
    : _createCommentVNode("", true)
}