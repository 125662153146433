<!-- eslint-disable vue/multi-word-component-names -->
<template>
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 246.34 247.94">
		<g id="dunkle_früchte" data-name="dunkle früchte">
			<path
				class="cls-1"
				d="M128.38,5.89c6,1.46,12.37,2.18,18,4.52C167.91,19.28,181,35.34,185,58.35a61.08,61.08,0,0,1-31.24,64c-22.52,12.09-45,10.85-66-3.77-16.87-11.74-25.75-28.51-26.13-49C61.24,46.72,71.1,28.8,90,16a59.06,59.06,0,0,1,27.7-9.77A8.77,8.77,0,0,0,119,5.89Z"
			></path>
			<path
				class="cls-1"
				d="M123.14,245.44c-27-.23-51.67-8.18-73.47-24.43a122.73,122.73,0,0,1-31.82-34.85c-1.26-2.06-1.84-4.12-.76-6.4,10.86-22.9,28.53-37.5,53.16-43.6,1.73-.44,3.52-.66,5.27-1,3.85-.84,7.14.26,10.31,2.49a64.58,64.58,0,0,0,41,11.87c11.43-.48,22.47-3.21,31.65-10,7.06-5.22,13.82-4.83,21.36-2.72,20.67,5.8,36.23,18.18,47.3,36.42,4.88,8.05,5.07,8.35-.08,16.1a123.46,123.46,0,0,1-103.89,56.14Z"
			></path>
		</g>
	</svg>
</template>
<style scoped>
.cls-1 {
	fill: var(--primary-color);
}
</style>
