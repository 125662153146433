import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Quiz = _resolveComponent("Quiz")!

  return (_openBlock(), _createBlock(_component_Quiz, {
    restaurantCode: _ctx.restaurantCode,
    autoRedirect: _ctx.autoRedirect,
    mode: _ctx.mode,
    transparentMode: _ctx.transparentMode,
    matchInfo: _ctx.matchInfo,
    language: _ctx.language
  }, null, 8, ["restaurantCode", "autoRedirect", "mode", "transparentMode", "matchInfo", "language"]))
}